import orders from './orders.json'
import { defaultProduct } from "./defaultProduct";
import { moreProducts } from "./moreProducts";

export const cableCategories = [
	'Alarm Cables',
	'Audio Cables',
	'Control Cables',
	'Data Cables',
	'Network Cables',
]

export const manufacturers = [
	'Philips',
	'AUX cable',
]

export const cableProduct = {
	...defaultProduct,
	title: 'Power Cables',
	artNo: 'MSGU50W93036D',
	ean: '8718696707692',
	mainCategory: 'Cables and Wires',
	subCategories: [...cableCategories],
}

let flags = {};

export const allProducts = []
	.concat.apply([], orders.map(o => o.products))
	.filter(p => {
		if (flags[p.ean]) {
			return false
		}
		flags[p.ean] = p.ean
		return true
	})
	.filter(p => !!p)
	.map(p => {
		return {
			...defaultProduct,
			...p,
		}
	})
export const products = [...allProducts, cableProduct, ...moreProducts];
