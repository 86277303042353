import { writable, derived } from "svelte/store";
import _ from "lodash"

import seller from "./data/seller.json";
import customer from "./data/customer.json";
import { products, cableCategories, manufacturers } from "./data/products";

const personaMap = {
	seller: seller,
	customer: customer
};

export const brand = writable('valtech')
export const themeColor = derived(brand, $brand => {
	$brand === "sonepar" ? '#142c6a' : '#007D32'
})

export const activePersonaKey = writable("seller");

export const activePersona = derived(activePersonaKey, $activePersonaKey => {
	return personaMap[$activePersonaKey]
});

export const revenue = derived(activePersonaKey, $activePersonaKey => {
	const persona = personaMap[$activePersonaKey]
	const values = _(persona.quotes)
		.concat(persona.orders)
		.map(item => item.amount)
		.value()

	return _.reduce(values, (sum, n) => { return sum + n }, 0).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
})

export const searchQuery = writable('')
export const filteredProducts = derived(searchQuery, $searchQuery => {
	const sq = $searchQuery.toLowerCase()

	if (sq === '') {
		return products
	} else {
		return products.filter(p => {
			return (_.includes(p.title.toLowerCase(), sq)
				|| _.includes(p.manufacturer.toLowerCase(), sq)
				|| _.includes(p.mainCategory.toLowerCase(), sq)
				|| (p.subCategories && _.includes(p.subCategories.join('').toLowerCase(), sq)))
		})
	}
})

export const filteredSubcategories = derived(searchQuery, $searchQuery => {
	return _(products)
		.filter(p => p.subCategories)
		.map(p => p.subCategories)
		.flattenDeep()
		.uniq()
		.filter(c => _.includes(c.toLowerCase(), $searchQuery.toLowerCase()))
		.value()
})
