<script>
	import LessMoreLink from "../_ui/LessMoreLink.svelte";
	import QuickLinks from "../_ui/QuickLinks.svelte";
	import Task from "./Task.svelte";
	import ArrowLink from "../_ui/ArrowLink.svelte";
	import Upcoming from "./Upcoming.svelte";
	import {activePersona, activePersonaKey, revenue} from "../../Store";
	import {fly, slide, fade} from "svelte/transition";
	import {flip} from "svelte/animate"
	import Card from "../_ui/Card.svelte";
	import Quote from "../Quote.svelte";

	let showAllTasks = false;
	let activeTask;

	function handleOnClickTask(id) {
		if (id === activeTask) {
			activeTask = undefined;
			return;
		}
		activeTask = id;
	}

</script>

<style>
	.tasks {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		gap: 10px;
		padding: 20px 0;
	}

	.tasks__container {
		display: grid;
		gap: 10px;
		grid-auto-rows: min-content;
	}

	.tasks__container::-webkit-scrollbar {
		display: none;
	}

	.tasks__container::-webkit-scrollbar-track {
		box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
	}

	.tasks__container::-webkit-scrollbar-thumb {
		background-color: #aaa;
	}

	.lessmore {
		justify-self: center;
	}

	.board {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		padding: 20px 0;
		grid-auto-flow: row dense;
		grid-auto-rows: min-content;
		align-items: start;
	}

	h2 {
		color: #fff;
	}

	.upcoming {
		display: none;
	}

	.tasks h2 {
		margin-bottom: 10px;
	}


	.tasks__headline {
		display: flex;
		justify-content: space-between;
	}

	.activeCart {
		margin: 0 0 30px;
	}

	@media (max-width: 640px) {
		.tasks__container {
			scroll-snap-type: x mandatory;
			display: flex;
			width: calc(100% + 20px);
			margin: 0 -10px;
			overflow-x: scroll;
			padding-left: 10px;
		}

		.tasks__container > div {
			padding: 0 10px 0 0;
			scroll-snap-align: center;
		}

		.lessmore {
			justify-self: center;
			display: none;
		}

		.board h2 {
			grid-column: auto;
			grid-row: auto;
		}

		.upcoming {
			display: block;
			width: 100%;
		}
	}
</style>

<div class="tasks">
	{#if $activePersonaKey === 'customer'}
		<div class="upcoming">
			<Upcoming message="Upcoming appointment" disableViewAll>asd</Upcoming>
		</div>

		<div
						in:slide={{ x: -200, duration: 500, delay: 500 }}
						out:slide={{ x: 200, duration: 500 }}>
			<QuickLinks links={$activePersona.quicklinks} title="Recently used"/>
		</div>
		<div class="activeCart"
			in:slide={{ x: -200, duration: 500, delay: 500 }}
			out:slide={{ x: 200, duration: 500 }}>
				<Card title={'Active Cart'} textLinkText={'Switch active cart'}>
					<Quote title={'Warehouse lighting project'} amount={909.87} subtitle={''} />
				</Card>
		</div>

		<h2 class="tasks__headline">
			Next best actions
			<ArrowLink>Sort by ...</ArrowLink>
		</h2>

		<div class="tasks__container">
			{#each $activePersona.tasks.slice(0, showAllTasks ? 999 : 4) as task (task.info)}
				<div transition:fly={{ duration: 500 }} animate:flip>
					<Task
									isCustomer={true}
									{...task}
									handleClick={() => handleOnClickTask(task.info)}
									active={activeTask === task.info} />
				</div>
			{/each}
		</div>

		{#if $activePersona.tasks.length > 4}
			<div class="lessmore" transition:fade>
				<LessMoreLink
								handleClick={() => (showAllTasks = !showAllTasks)}
								showMore={showAllTasks} />
			</div>
		{/if}
	{:else}
		<div class="upcoming">
			<Upcoming message="Upcoming appointment" disableViewAll>asd</Upcoming>
		</div>

		<h2 class="tasks__headline">
			Next best actions
			<ArrowLink>Sort by ...</ArrowLink>
		</h2>

		<div class="tasks__container">
			{#each $activePersona.tasks.slice(0, showAllTasks ? 999 : 6) as task (task.info)}
				<div transition:fly={{ duration: 500 }} animate:flip>
					<Task
									{...task}
									handleClick={() => handleOnClickTask(task.info)}
									active={activeTask === task.info} />
				</div>
			{/each}
		</div>

		{#if $activePersona.tasks.length > 6}
			<div class="lessmore" transition:fade>
				<LessMoreLink
								handleClick={() => (showAllTasks = !showAllTasks)}
								showMore={showAllTasks} />
			</div>
		{/if}

		<div
						in:slide={{ x: -200, duration: 500, delay: 500 }}
						out:slide={{ x: 200, duration: 500 }}>
			<QuickLinks links={$activePersona.quicklinks}/>
		</div>
	{/if}
</div>
