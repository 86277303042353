<script>
	import {push} from 'svelte-spa-router'
	import {fade} from 'svelte/transition';
	import Dropdown from "../_ui/Dropdown.svelte";
	import checkout from "../../data/checkout.json";
	import Task from "../tasks/Task.svelte";
	import RadioButtonGroup from "../_ui/RadioButtonGroup.svelte";
	import OrderOverview from "../OrderOverview.svelte";
	import CreditLimitCard from "../_cards/CreditLimitCard.svelte";
	import AddressCard from "../_ui/AddressCard.svelte";
	import Textarea from "../_ui/Textarea.svelte";
	import Button from "../_ui/Button.svelte";

	export let activeTask = 'Delivery';
	export let activeStep = "delivery";
	// export let checkoutProcess = checkout[0].steps[activeStep];
	export let checkoutProcess = checkout[0].steps[activeStep];
</script>

<style>
	.allorders {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		gap: 10px;
		padding: 20px 0;
	}
	.orderdetail {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-auto-rows: min-content;
		gap: 10px 20px;
		padding: 20px 0;
		grid-auto-flow: dense row;
	}

	.orderdetail h2 {
		grid-column: span 2;
	}

	.shopcard {
		grid-column: 1;
	}

	.orderoverview {
		grid-column: 2;
		grid-row: span 4;
	}

	.orderoverview__container {
		display: grid;
		grid-row-gap: 10px;
	}

	.orderDetails {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		background: #fff;
		padding: 25px;
	}

	.addAddress {
		color: #233691;
		font-size: 20px;
		font-weight: normal;
		margin: 15px 0 0 36px;
		cursor: pointer;
	}
</style>

<div
				class="allorders"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	{#each checkout as check (check.message)}
		<Task
						message={check.message}
						client={check.client}
						preventClient={true}
						preventInfo={true}
						preventDate={true}
						order
						active={check.message === activeTask}
						handleClick={() => (activeTask = check.message)}/>
	{/each}
</div>

<div
				class="orderdetail"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	<div>
		<div class="orderDetails">
			<Dropdown title={checkoutProcess.deliveryAddress.title} open={checkoutProcess.deliveryAddress.open}>
				<RadioButtonGroup radioButtons={checkoutProcess.deliveryAddress.radioButtons}/>
				<div class="addAddress">+ Add new address</div>
			</Dropdown>
			<Dropdown title={checkoutProcess.deliveryContact.title} open={checkoutProcess.deliveryContact.open}>
				<div class="address">
					<p>
						<b>{checkoutProcess.deliveryContact.contact.firstName} {checkoutProcess.deliveryContact.contact.lastName}</b></p>
					<p>Phone: {checkoutProcess.deliveryContact.contact.phone}</p>
				</div>
			</Dropdown>
			<Dropdown title={checkoutProcess.deliverySchedule.title} open={checkoutProcess.deliverySchedule.open}>
				<img class="calendar" src={checkoutProcess.deliverySchedule.calendarImage} alt=""/>
			</Dropdown>
			<Dropdown title={checkoutProcess.deliveryNotes.title} open={checkoutProcess.deliveryNotes.open}>
				<Textarea placeholder={checkoutProcess.deliveryNotes.placeholder}/>
			</Dropdown>
		</div>
	</div>
	<div class="orderoverview">
		<div class="orderoverview__container">
			<OrderOverview handleOnClickApprove={() => push('/checkout/payment')} approveButtonText="Choose payment method">
				<Button variant="transparent" size="slim" on:click={() =>push('/order')}>Return to cart</Button>
			</OrderOverview>
			<CreditLimitCard/>
			<AddressCard/>
		</div>
	</div>
</div>

