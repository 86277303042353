<script>
	import RadioButton from "./RadioButton.svelte";

	export let radioButtons = [
		{
			label: 'Kicklasch GmbH',
			address: ['Scharnhorststraße 1', '50733 Köln'],
			delivery: '04. May 2021',
		},
		{
			label: 'WOLFF & MÜLLER Holding',
			address: ['Deutz-Mülheimer Str. 109', '51063 Köln'],
			delivery: '04. May 2021',
		},
	]

	let selected = radioButtons[0].label;

	function handleOnChange(id) {
		selected = id;
	}
</script>

<style>
	.radioButtonGroup {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 40px;
	}

	.extendedLabel {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		color: #000;
	}

	.headline {
		font-weight: 600;
		font-size: 16px;
	}

	.text {
		font-weight: normal;
	}
</style>

<div class="radioButtonGroup">
	{#each radioButtons as radio, index (radio.label)}
		<RadioButton checked={selected === radio.label} onchange={() => handleOnChange(radio.label)}>
			<div class="extendedLabel">
				<div>
					<div class="headline">{radio.label}</div>
					<div class="text">
						{#each radio.address as address}
							<div>{address}</div>
						{/each}
					</div>
				</div>
				<div>
					<div class="headline">Estimated delivery:</div>
					<div class="text">{radio.delivery}</div>
				</div>
			</div>
		</RadioButton>
	{/each}
</div>
