<script>
	import _shuffle from 'lodash/shuffle';
	import Orders from "./Orders.svelte";
	import { products as defaultProducts } from '../../data/products'
	export let products = _shuffle(defaultProducts).map((p, k) => {
		if (k < 6) {
			return {
				title: p.title,
				image: p.ean,
				subtitle: `Article no. ${p.artNo}`,
				amount: k,
				nextActions: [{name: 'View Product'}, {name: 'View Order'}]
			}
		} else {
			return false
		}
	}).filter(p => !!p);


</script>

<Orders orders={products} />
