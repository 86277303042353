<script>
	import {fade} from 'svelte/transition';
	import {createEventDispatcher} from 'svelte';
	import BackButton from "../components/_ui/BackButton.svelte";
	import TextLink from "../components/_ui/TextLink.svelte";
	import AmountCounterButton from "../components/_ui/AmountCounterButton.svelte";
	import Button from "../components/_ui/Button.svelte"
	import TabNavigation from "../components/_ui/TabNavigation.svelte";
	import ProductSlider from "../components/_ui/ProductSlider.svelte";

	export let product;
	export let backTo = '';
	const dispatch = createEventDispatcher();
	const thumbnailsToUse = [product.ean, ...product.thumbnails]

	function handleOnClickBack() {
		dispatch('onBackClick', '')
	}

	function handleProductClick(e) {
		console.log('handle product click: ', e.detail)
		dispatch('onProductClick', {id: e.detail.id})
	}
</script>

<style>
	.productDetail {
		position: relative;
		grid-template-columns: 1fr;
		display: grid;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
		padding: 50px 55px 40px 50px;
	}

	.backButtonContainer {
		position: absolute;
		top: 10px;
		left: 40px;
	}

	.headline {
		text-align: center;
		color: #333333;
		font-weight: normal;
		font-size: 22px;
		margin: 0 0 40px;
	}

	.mainContainer {
		display: grid;
		grid-template-columns: auto 1fr 515px;
		height: 315px;
		gap: 30px;
		margin: 0 0 40px;
	}
	.thumbnailSection {
		display: grid;
		grid-template-rows: 62px 62px;
		gap: 10px;
	}
	.thumbnailContainer {
		width: fit-content;
		border: 1px solid #233691;
		padding: 6px;
	}

	.thumbnail {
		width: 50px;
		height: 50px;
		object-fit: contain;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	.detail {
		display: grid;
		grid-template-columns: 265px 165px;
		padding: 25px 32px 30px 25px;
		width: 100%;
		height: 100%;
		background: #F5FAFF;
		color: #333333;
		gap: 33px
	}
	.detailInfo {
		margin: 0 0 30px;
	}
	.detailAvailability {
		margin: 0 0 23px;
	}
	.detailPriceSection {
		text-align: right;
	}
	.grossRecupel {
		display: grid;
		grid-template-columns: auto auto;
		grid-row-gap: 25px;
		grid-column-gap: 2px;
		margin: 0 0 13px;
	}
	.grossRecupel__name {
		text-align: left;
	}
	.price {
		color: #142C6A;
		font-weight: 600;
		font-size: 38px;
		margin: 0 0 20px;
	}
	.pcs {
		font-weight: normal;
		font-size: 14px;
		margin: 0 0 14px;
	}
	.buttonSection {
		display: grid;
		gap: 15px;
	}
	p {
		margin: 0;
	}
	.detailInfo p {
		margin: 0 0 13px;
	}
	.detailAvailability p {
		margin: 0 0 8px;
	}
</style>

<div class="productDetail"
		 in:fade={{ x: -200, duration: 500, delay: 500 }}
		 out:fade={{ x: 200, duration: 500 }}>
	<div class="backButtonContainer">
		<BackButton on:click={handleOnClickBack} text={`Back ${backTo ? 'to "' + backTo + '"' : '' }`}/>
	</div>
	<div class="headline">{product.title}</div>
	<div class="mainContainer">
		<div class="thumbnailSection">
		{#each thumbnailsToUse as image, index (image)}
			<div class="thumbnailContainer">
				<img class="thumbnail" src={`img/products/${image}.jpg`} alt=""/>
			</div>
		{/each}
		</div>
		<img class="image" src={`img/products/${product.ean}.jpg`} alt=""/>
		<div class="detail">
			<div>
				<div class="detailInfo">
					<p><b>Article:</b> {product.artNo}</p>
					<p><b>EAN:</b> {product.ean}</p>
					<p><b>Supplier SKU:</b> {product.ean}</p>
					<p><b>Your Number:</b> {product.ean}</p>
				</div>
				<div class="detailAvailability">
					<p><b>Availability:</b> <span style={`color: ${product.availabilityColor}`}>{product.availability}</span></p>
					<TextLink>See all warehouses</TextLink>
				</div>
				<div>
					Order today before 6 pm and have it delivered in 3 working days
				</div>
			</div>
			<div class="detailPriceSection">
				<div class="grossRecupel">
					<div>Gross:</div>
					<div>{product.gross}</div>
					<div>Recupel</div>
					<div>{product.recupel}</div>
				</div>
				<div class="price">
					$ {product.totalPrice}
				</div>
				<!-- <div class="pcs">{product.pcs}</div> -->
				<div class="buttonSection">
					<AmountCounterButton extended={true} size="fullWidth"/>
					<Button size="thick">Add to cart</Button>
				</div>
			</div>
		</div>
	</div>
	<TabNavigation/>
	<ProductSlider on:onProductClick={handleProductClick}/>
</div>
