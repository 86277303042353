<script>

	export let label = '';
	export let checked = false;
	export let onchange;
	$:iconToUse = checked ? 'radio-selected' : 'radio'

	function handleOnChange() {
		if (onchange) {
			onchange()
		} else {
			checked = !checked
		}
	}
</script>

<style>
	.radio {
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}
	.icon {
		width: 21px;
		height: 21px;
		object-fit: contain;
		margin: 0 13px 0 0;
	}
	.label {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		width: 100%;
	}
</style>

<div class="radio" on:click={handleOnChange}>
	<img class="icon" src={`icons/${iconToUse}.svg`} alt="">
	<div class="label">
		{@html label}
		<slot/>
	</div>
</div>
