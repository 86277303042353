<script>
	import Button from "./_ui/Button.svelte";
	import {afterUpdate} from "svelte";

	export let price = Math.random() * 100000;
	export let deliveryDate = "2021-05-04";
	export let handleOnClickApprove = () => true;
	export let approveButtonText = 'Check out'
	let orderlines = (Math.random() * 10 + 1).toFixed(0);

	Date.prototype.addDays = function (days) {
		var date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};

	let newDate = new Date().addDays(Math.random() * 15 + 5);

	afterUpdate(() => {
		newDate = new Date().addDays(Math.random() * 15 + 5);
	});
</script>

<style>
  .OrderOverview {
    background: #fff;
    border-radius: 5px;
    padding: 25px 20px;
    display: grid;
    gap: 15px;
  }

  h2 {
    font-size: 21px;
    line-height: 25px;
    color: #333;
    font-weight: 600;
  }

  .details {
    display: grid;
    gap: 7px;
    grid-template-columns: auto auto;
  }

  .value {
    justify-self: end;
    font-size: 16px;
    font-weight: 600;
  }
</style>

<div class="OrderOverview">
  <h2>Overview</h2>

  <div class="details">
    <div class="pos">Total gross price</div>
    <div class="value">$ {price.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>

    <div class="pos">Total net price</div>
    <div class="value">$ {(price * 0.81).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>

    <div class="pos">Recupel</div>
    <div class="value">$ {(price * 0.19).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>

    <div class="pos">&nbsp;</div>
    <div class="value">&nbsp;</div>

    <div class="pos">Order lines</div>
    <div class="value">{orderlines}</div>

    <div class="pos">&nbsp;</div>
    <div class="value">&nbsp;</div>

    <div class="pos">Total</div>
    <div class="value">$ {price.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>

<!--    <div class="pos">&nbsp;</div>-->
<!--    <div class="value">&nbsp;</div>-->

<!--    <div class="pos">Expected delivery date</div>-->
<!--    <div class="value">-->
<!--      {newDate.toLocaleString(undefined, {-->
<!--        month: 'numeric',-->
<!--        day: 'numeric',-->
<!--        year: 'numeric'-->
<!--      })}-->
<!--    </div>-->

<!--    <div class="pos">&nbsp;</div>-->
<!--    <div class="value">&nbsp;</div>-->

<!--    <div class="pos">Quotation discount</div>-->
<!--    <div class="value">$ {(price * 0.15).toFixed(2).toLocaleString()}</div>-->

<!--    <div class="pos">Quote total</div>-->
<!--    <div class="value">-->
<!--      $ {(price - price * 0.15).toFixed(2).toLocaleString()}-->
<!--    </div>-->
  </div>

  <Button on:click={handleOnClickApprove}>{approveButtonText}</Button>
	<slot/>
</div>
