<script>
	import SelectedFilterButton from './SelectedFilterButton.svelte'
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	export let selectedFilters = [
		'Philips Lighting',
		'Schneider Electric',
		'Copper pipe',
		'Cables and wires',
		'Cable Control Command',
		'Handling',
		'Network cable',
		'Arkos Light',
	]
	function handleDeleteFilter(id) {
		selectedFilters = selectedFilters.filter(f => f !== id)
		dispatch('removeFilter', {id: id})
	}
</script>

<style>
	.selectedFilterGroup {
		display: flex;
		flex-wrap: wrap;
	}

	.filterContainer {
		margin: 0 15px 20px 0;
	}

	.filterContainer:last-child {
		margin: 0 0 20px 0;
	}
</style>

<div class="selectedFilterGroup">
	{#each selectedFilters as filter}
		<div class="filterContainer">
			<SelectedFilterButton on:click={() => handleDeleteFilter(filter)}>{filter}</SelectedFilterButton>
		</div>
	{/each}
</div>
