<script>
	import ProgressBar from "../_ui/ProgressBar.svelte";

	export let title = 'Company Credit Limit';
	export let creditLimit = {
		total: 500000.00,
		available: 48983.50,
	}

</script>

<style>
	.creditLimitCard {
		background: #fff;
		border-radius: 5px;
		padding: 25px 20px;
	}

	.title {
		font-weight: normal;
		font-size: 21px;
		color: #333333;
		margin: 0 0 20px;
	}

	.values {
		display: flex;
		justify-content: space-between;
		margin: 0 0 20px;
		color: #333333;
	}

	.pos {
		font-weight: normal;
		font-size: 14px;
	}

	.value {
		font-size: 16px;
		font-weight: 600;
	}
</style>

<div class="creditLimitCard">
	<div class="title">{title}</div>
	<div class="values">
		<div>
			<div class="pos">Available</div>
			<div class="value">$ {creditLimit.available.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>
			<!-- <div class="value">$ 48,983.50</div> -->
		</div>
		<div>
			<div class="pos">Total</div>
			<div class="value">$ {creditLimit.total.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>
		</div>
	</div>

	<ProgressBar value={creditLimit.available} max={creditLimit.total}/>
</div>
