<script>
	import {defaultProduct} from "../../data/defaultProduct";
	import Card from "../_ui/Card.svelte";
	import AmountCounterButton from "../_ui/AmountCounterButton.svelte";
	import Button from "../_ui/Button.svelte";
	import TextLink from "../_ui/TextLink.svelte";
	import {brand} from "../../Store.js";

	export let title =
					"Solar panel - Q.peak DUO G8 - 345Wp - MONO - 1740x1030x32 - Q Cells";
	export let artNo = "QPEAKDU0G8345";
	export let ean = "8718696707245";
	export let totalPrice = 325;
	export let pcs = 1;
	export let gross = 262.7;
	export let recupel = 14.43;
	export let textLink = "all-warehouses";
	export let linkText = "See all";
	export let nearestWarehouse = "St Gallen";
	export let hasOrderButtons = false;
	export let availability = 'In stock';
	export let availabilityColor = '#00A651';

	export let reduced = true;
</script>

<style>
	.shopCard {
		display: grid;
		grid-template-columns: 100px 1fr;
		gap: 10px;
	}

	.details {
		width: 100%;
	}

	.detailsReduced {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		width: 17px;
		height: 17px;
		object-fit: contain;
	}

	.infoContainer {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.availabilityContainer {
		font-weight: normal;
		font-size: 14px;
		color: #888888;
	}
	.availability {
		margin: 5px 0 0 0;
		font-weight: 600;
	}
	.priceReduced {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 12px;
		align-items: end;
	}
	.image {
		width: 100%;
		height: 100px;
		object-fit: contain;
	}

	.title {
		font-size: 16px;
		color: #333333;
		font-weight: 600;
		padding: 0 50px 0 0;
	}

	.price {
		font-size: 32px;
		font-weight: 600;
		white-space: nowrap;
	}

	.price.sonepar {
		color: #142c6a;
	}

	.price.unie {
		color: #007d32;
	}

	.detailsRows {
		display: grid;
		gap: 20px;
	}

	.productDetails {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 20px;
	}

	.priceSection {
		text-align: right;
	}

	.tabSection {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 20px;
		grid-row-gap: 5px;
		margin: 0 0 10px 0;
	}

	.notes {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.last {
		margin: -32px 0 0 0;
	}

	.stock {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 20px;
	}

	.warehouseSpecs {
		display: grid;
		gap: 5px;
	}

	.text {
		font-size: 14px;
		color: #888888;
		white-space: nowrap;
	}

	.pcs {
		color: #333333;
	}

	.buttonSection {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media only screen and (max-width: 641px) {
		.shopCard,
		.productDetails {
			grid-template-columns: 1fr;
			justify-items: center;
		}

		.shopCard {
			row-gap: 20px;
		}

		.image {
			width: 140px;
		}

		.title {
			text-align: center;
		}

		.last {
			margin: 0;
		}

		.priceDetail,
		.ean {
			display: none;
		}
	}
</style>

<Card disableViewAll={true}>
	<div class="shopCard" class:sonepar={$brand === 'sonepar'} class:unie={$brand === 'unie'}  class:valtech={$brand === 'valtech'}>
		<img class="image" alt="" src={`img/products/${ean}.jpg`}/>

		<div class="details">
			{#if reduced}
				<div class="detailsReduced">
					<img class="close" src={'/icons/close.svg'} alt=""/>
					<div class="title">{title}</div>
					<div class="infoContainer">
						<div class="availabilityContainer">
							<div>Availability:</div>
							<div class="availability" style={`color: ${availabilityColor}`}>{availability}</div>
						</div>
						<div class="priceReduced">
							<div class="priceSection price">$ {totalPrice}</div>
							<div class="pcs">{pcs} pcs</div>
						</div>
					</div>
				</div>
			{:else}
				<div class="detailsRows">
					<div class="title">{title}</div>
					<div class="productDetails">
						<div class="textSection">
							<div class="tabSection text">
								<div>Article:</div>
								<div>{artNo}</div>

								<div class="ean">EAN:</div>
								<div class="ean">{ean}</div>
							</div>
						</div>

						<div class="priceSection">
							<div class="tabSection text priceDetail">
								<div>Gross:</div>
								<div>
									{gross.toLocaleString(undefined, {
									style: 'currency',
									currency: 'USD'
									})}
								</div>
								<div>Recupel:</div>
								<div>
									{recupel.toLocaleString(undefined, {
									style: 'currency',
									currency: 'USD'
									})}
								</div>
							</div>
							<div
											class="price"
											class:valtech={$brand === 'valtech'}
											class:sonepar={$brand === 'sonepar'}
											class:unie={$brand === 'unie'}>
								{totalPrice.toLocaleString(undefined, {
								style: 'currency',
								currency: 'USD'
								})}
							</div>
						</div>
					</div>
					<div class="notes last text">
						<div class="warehouseSpecs">
							<div>Your nearest warehouse:</div>
							<div class="stock">
								<div class="pcs">{nearestWarehouse} - {pcs} pcs</div>
								<TextLink text={linkText} linkTo={textLink}/>
							</div>
						</div>
						<div class="pcs">{pcs} pcs</div>
					</div>
				</div>
			{/if}

			{#if hasOrderButtons}
				<div class="buttonSection">
					<AmountCounterButton/>

					<Button>Add to cart</Button>
				</div>
			{/if}
		</div>
	</div>
</Card>
