<script>
  import { revenue } from "../../Store";
  import { CountUp } from "countup.js";
  import { afterUpdate, onMount } from "svelte";
  import { brand } from "../../Store.js";

  export let increasedVsPreviousYear = 13;

  let revenueToUse;

  onMount(() => {
    revenueToUse = new CountUp("countUp", $revenue, { separator: "." });
    if (!revenueToUse.error) {
      revenueToUse.start();
    } else {
      console.log(revenueToUse.error);
    }
  });

  afterUpdate(() => {
    if (!revenueToUse.error) {
      revenueToUse.update($revenue);
    } else {
      console.log(revenueToUse.error);
    }
  });
</script>

<style>
  .revenueCounter {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px 0 0 0;
  }

  .counter {
    display: flex;
    font-size: 38px;
    color: #142c6a;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  .counter.unie {
    filter: hue-rotate(245deg);
	}

	.counter.valtech {
    color: #000000;
  }

  .increased {
    font-size: 16px;
    color: #979797;
  }

  span {
    color: #00a651;
  }
</style>

<div class="revenueCounter">
  <div class="counter" class:unie={$brand === 'unie'} class:valtech={$brand === 'valtech'}>
    <div>$&nbsp;</div>
		<!-- <div id="countUp" /> -->
		<div>4,335,851</div>
  </div>
  <div class="increased">
    <span>{increasedVsPreviousYear} %</span>
    vs. prev. year
  </div>
</div>
