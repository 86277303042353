<script>
	import {push, location} from 'svelte-spa-router';
	import ShopCard from "../components/_cards/ShopCard.svelte";
	import Task from "../components/tasks/Task.svelte";
	import OrderOverview from "../components/OrderOverview.svelte";
	import {fly, slide, scale, blur, fade} from "svelte/transition";
	import orders from "../data/orders.json";
	import _shuffle from "lodash/shuffle";
	import _range from "lodash/range";
	import _find from "lodash/find";
	import {defaultProduct} from "../data/defaultProduct";
	import CreditLimitCard from "../components/_cards/CreditLimitCard.svelte";
	import AddressCard from "../components/_ui/AddressCard.svelte";
	import Button from "../components/_ui/Button.svelte";

	let activeTask = "Warehouse lighting project";
	let linkTextToUse = defaultProduct.linkText;
	let prevLocation = ''

	function handleClickTask(order) {
		prevLocation = $location
		activeTask = order.message
	}

</script>

<style>
	h2 {
		color: #fff;
		margin: 0 0 10px;
	}

	.allorders {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		gap: 10px;
		padding: 20px 0;
	}

	.orderdetail {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-auto-rows: min-content;
		gap: 10px 20px;
		padding: 20px 0;
		grid-auto-flow: dense row;
	}

	.orderdetail h2 {
		grid-column: span 2;
	}

	.shopcard {
		grid-column: 1;
	}

	.orderoverview {
		grid-column: 2;
		grid-row: span 8;
	}

	.orderoverview__container {
		display: grid;
		grid-row-gap: 10px;
	}
</style>

<div
				class="allorders"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	<h2>Carts</h2>
	{#each orders.filter(o => o.isCart) as order (order.message)}
		<Task
						message={`Cart ${typeof order.message === 'number' ? '#' : '- '}${order.message}`}
						info={`$ ${order.info}`}
						date={order.date}
						preventClient={true}
						order
						active={order.message === activeTask}
						handleClick={() => handleClickTask(order)}
		/>
	{/each}
</div>

<div
				class="orderdetail"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	{#if activeTask !== 0}
		<h2>{`Cart ${typeof activeTask === 'number' ? '#' : '- '}${activeTask}`}</h2>

		{#each _find(orders, order => order.message === activeTask).products as product (activeTask + product.ean)}
			<div class="shopcard">
				<ShopCard {...product} linkText={linkTextToUse}/>
			</div>
		{/each}

		<div class="orderoverview">
			<div class="orderoverview__container">
				<OrderOverview
								handleOnClickApprove={() => push('/checkout')}
								deliveryDate={_find(orders, order => order.message === activeTask).date}
												price={_find(orders, order => order.message === activeTask).info}>
					<Button variant="secondary">Request Quote</Button>
					<Button variant="transparent" size="slim">Discard</Button>
				</OrderOverview>
				<CreditLimitCard/>
				<AddressCard/>
			</div>
		</div>
	{/if}
</div>
