<script>
	import TreeNode from "./TreeNode.svelte";
	import { slide } from 'svelte/transition';
	import { brand } from "../../Store.js";

	export let title = '';
	export let breadcrumb = []
	export let nodes = false;
	export let onClick = () => true;
	export let selectedTreeNode = 'none'

	let nodesVisible = false

	let titleToUse = title.split('(')[0]
	let amountToUse = '(' + title.split('(')[1]

	function handleToggleNodes() {
		if (!!nodes) {
			nodesVisible = !nodesVisible;
		}
		onClick(breadcrumb, nodesVisible)
	}
</script>

<style>
	.treeNode {
		cursor: pointer;
		color: #233691;
	}

	.title {
		margin: 0 0 10px;
	}

	.titleInner {
		position: relative;
		z-index: 2;
	}

	.titleInner.valtech {
		color: #000000;
	}

	.amount {
		color: #333333;
		font-size: 12px;
	}

	.titleActive {
		font-weight: bold;
		color: #FFFFFF;
		position: relative;
	}

	.titleActive .amount {
		/* color: #fff; */
	}

	.activeBackground {
		position: absolute;
		top: -5px;
		right: 0;
		bottom: -5px;
		left: -100px;
	}

	.nodes {
		padding: 5px 0 1px 20px;
		font-size: 13px;
	}
	ul, li {
		margin: 0;
		list-style: none;
	}
	li {
		margin: 0 0 10px
	}
</style>

<li class="treeNode">
	<div class="title" class:titleActive={titleToUse.trim() === selectedTreeNode} on:click={handleToggleNodes}>
		<div class:activeBackground={titleToUse.trim() === selectedTreeNode}></div>
		<div class={`titleInner ${$brand}`}>{titleToUse}<span class="amount">{amountToUse}</span></div>
	</div>
	{#if nodesVisible && !!nodes}
		<ul class="nodes" transition:slide>
			{#each nodes as node, index (node.title + index)}
				<TreeNode {...node} onClick={onClick} selectedTreeNode={selectedTreeNode}/>
			{/each}
		</ul>
	{/if}
</li>
