<script>
	import {slide} from 'svelte/transition'
	import {createEventDispatcher} from 'svelte';

	const dispatch = createEventDispatcher();

	export let id = 'Dropdown' + Math.random() * 100000;
	export let title = 'Dropdown'
	export let open = false;

	function handleToggleDropdown() {
		open = !open;
		dispatch('onToggle', {open: open, id: id, title: title})
	}
</script>

<style>
	.dropdown {
		width: 100%;
		user-select: none;
	}

	.header {
		max-width: 288px;
		padding: 14px 5px 12px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.title {
		color: #233691;
	}

	.icon {
		width: 16px;
		height: 16px;
		object-fit: contain;
		transform: rotate(0deg);
		transition: transform 200ms ease-out;
	}

	.iconRotated {
		transform: rotate(180deg);
	}

	.content {
		width: 100%;
		padding: 20px 0;
	}
	.border {
		width: 100%;
		max-width: 288px;
		height: 1px;
		background: #D8D8D8;
	}
</style>

<div class="dropdown">
	<div on:click={handleToggleDropdown} class="header">
		<div class="title">{title}</div>
		<img class="icon" class:iconRotated={open} src={'icons/dropdown-arrow-down.svg'} alt=""/>
	</div>
		{#if open}
			<div class="content" transition:slide>
				<slot/>
			</div>
		{/if}
	<div class="border"/>
</div>
