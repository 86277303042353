export const defaultProduct = {
	title: 'Maxus Deco Led - bulb ND 4.3-40w E27 - Philips',
	image: 'light-bulb',
	artNo: 'MSGU50W93036D',
	ean: '4058075094956',
	totalPrice: 280,
	pcs: 10,
	gross: 181.7,
	recupel: 6.7,
	currency: 'USD',
	locale: 'en-US',
	textLink: 'all-warehouses',
	linkText: 'See all',
	availability: 'In stock',
	availabilityColor: '#00A651',
	thumbnails: ['detail-second'],
	mainCategory: 'Lights',
	subCategories: ['light bulb', 'LED', 'led'],
	manufacturer: 'Philips',
}
