import App from './App.svelte';
import "./styles/global.css"
import "./styles/fonts.css"
import "./styles/fonts-valtech.css"

const app = new App({
	target: document.body
});

export default app;
