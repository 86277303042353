<script>
	export let placeholder = "Enter additional notes";
</script>

<style>
	textarea {
		width: 100%;
		max-width: 288px;
		resize: none;
		border: 1px solid #787878;
		border-radius: 4px;
		box-shadow: none;
		padding: 10px 14px;
	}
</style>

<label>
	<textarea rows="5" placeholder={placeholder} ></textarea>
</label>
