<script>
  import { slide } from "svelte/transition";
  import { push } from "svelte-spa-router";

  import DateDisplay from "../_ui/DateDisplay.svelte";

  export let isCustomer = false;
  export let message = "Order value decrease";
  export let client = "Reindert installatietechniek";
	export let info = "Decline 23%";
	export let info2 = "";
  export let date = "2021-03-01 ";
  export let icon = "increase";
  export let note = '';
  export let order = false;
  export let active = false;
  export let preventClient = false;
  export let preventDate = false;
	export let preventInfo = false;
	export let preventInfo2 = false;
  export let preventNote = false;
  export let handleClick = () => true;
  export let nextActions = [
    {
      name: "View order history",
      action: null
    },
    {
      name: "View contact",
      action: null
    }
  ];

  const getDate = d => {
    const quotedate = new Date(d).toLocaleString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
    console.log(typeof quotedate, quotedate);
    return quotedate !== "Invalid Date" ? quotedate : d;
  };
</script>

<style>
  .task {
    font-size: 14px;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

	.isCustomer .content {
		gap: 2px;
	}
	.isCustomer .info {
		grid-column: 1 / 3;
	}
	.isCustomer .note {
		display: block;
		color: #888888;
	}

  .main {
    background-color: #fff;
    display: grid;
    grid-template-columns: 25px 1fr;
    padding: 12px 15px;
    gap: 0 20px;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0);
    border-radius: 5px;
    overflow: hidden;
  }

  .main:hover {
    background-color: #f7f7f7;
    transform: translate(0px, -2px);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .order {
    grid-template-columns: 1fr;
    cursor: pointer;
  }

  .task.active .order:hover {
    box-shadow: -5px 10px 10px -5px rgba(0, 0, 0, 0.1);
  }

  .main.active {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }

  .content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 6px;
  }

  .icon {
    width: 100%;
    object-fit: contain;
  }

  .message {
    grid-column: 1 / 3;
  }

  .client {
    grid-column: 1 / 3;
  }

  .info {
    color: #888888;
  }

	.content .info span {
		display: block;
	}

	.note {
		display: none;
	}

  .date {
    color: #888888;
    justify-self: end;
		align-self: end;
  }

  .indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    width: 0px;
    background: #fff;
    clip-path: polygon(0 3%, 0 97%, 100% 50%);
    transition: all 0.3s ease-in-out, width 0.2s ease-out, right 0.2s ease-out;
  }

  .indicatorActive {
    width: 20px;
    right: -19px;
  }

  .order:hover ~ .indicatorActive {
    background-color: #f7f7f7;
    transform: translate(0px, -2px);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .actions {
    padding: 17px 10px 10px 10px;
    width: 100%;
    grid-column: span 2;
    background-color: #f2f2f2;
    position: relative;
    top: -7px;
    border-radius: 0 0 5px 5px;
    z-index: 1;
    margin-bottom: -7px;
  }

  .action {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .action:hover {
    text-decoration: underline;
  }

  .action:last-child {
    border-bottom: 0px solid #e0e0e0;
  }
	.preventClient {
		visibility: hidden;
	}
	.preventInfo {
		visibility: hidden;
	}

	.preventDate {
		visibility: hidden;
	}
	.preventNote {
		visibility: hidden;
	}

  @media (max-width: 640px) {
    .task {
      display: flex;
      width: 290px;
      transition: all 0.3s ease-in-out;
    }

    .main {
      flex-grow: 99;
    }

    .actions {
      display: none;
    }
  }
</style>

<div class="task" class:isCustomer class:active on:click={handleClick}>
  <div class="main" class:order class:active={active && !order}>
    {#if !order}
      <div class="icon">
        <img src={`icons/${icon}.svg`} alt={message} />
      </div>
    {/if}

    <div class="content">
      <h3 class="message">{message}</h3>
      <div class="client" class:preventClient={preventClient}>{client}</div>
      <div class="info" class:preventInfo={preventInfo}>{info.toLocaleString('en-US', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})} <span class="info2" class:preventInfo2={preventInfo2}>{info2.toLocaleString('en-US', {style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2})}</span></div>
			<div class="note" class:preventNote={preventNote}>{note}</div>
      <div class="date" class:preventDate={preventDate}>{getDate(date)}</div>
    </div>
  </div>

  {#if active && !order}
    <div class="actions" class:active transition:slide>
      {#each nextActions as action (action.name)}
        <div
          class="action"
          on:click={() => {
            if (action.action === 'approve-quote') {
              push('/order');
            }
          }}>
          {action.name}
        </div>
      {/each}
    </div>
  {/if}

  <div class="indicator" class:indicatorActive={active && order} />
</div>
