<script>
	export let title = 'Valtech Sales';
	export let subtitle = 'Your sales representative';
	export let name = 'Dean Briggs';
	export let phone = '+1 503-861-3620';
	export let mail = 'd.briggs@company.com';
	import { brand } from "../../Store.js";
</script>

<style>
	.addressCard {
		background: #FFFFFF;
		border-radius: 5px;
		display: grid;
		gap: 15px;
		padding: 20px;
	}
	.title {
		font-weight: normal;
		font-size: 21px;
		color: #333333;
	}
	.subtitle {
		font-weight: normal;
		font-size: 16px;
		color: #000000;
	}
	.address {
		font-weight: normal;
		font-size: 16px;
		color: #000000;
	}
	.mail {
		font-weight: 600;
		font-size: 16px;
		color: #263F92;
	}
	.mail.valtech {
		color: #000000;
		text-decoration: underline;
	}
</style>

<div class="addressCard">
	<div class="title">{title}</div>
	<div class="subtitle">{subtitle}</div>
	<div class="address">
		<div>{name}</div>
		<div>{phone}</div>
	</div>
	<div class="mail" class:valtech={$brand === 'valtech'}>{mail}</div>
</div>
