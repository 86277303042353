<script>
  export let orders;
  import Quote from "../Quote.svelte";
  import { slide } from "svelte/transition";
  import { flip } from "svelte/animate";

  let activeQuote = '';
  function handleOnClick(id) {
  	console.log(id)
  	if (activeQuote === id) {
  		activeQuote = '';
		} else {
  		activeQuote = id;
		}
	}

  const getDate = d => {
    const quotedate = new Date(d).toLocaleString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
    return quotedate !== "Invalid Date" ? quotedate : d;
  };
</script>

<div class="Orders">
  {#each orders as order (order.title + order.subtitle + order.amount)}
    <div
			on:click={() => handleOnClick(order.title + order.subtitle + order.amount)}
      in:slide={{ x: -200, duration: 500, delay: 500 }}
      out:slide={{ x: 200, duration: 500 }}>
      <Quote
        {...order}
        subtitle={order.date ? getDate(order.date) : order.subtitle}
				active={activeQuote === order.title + order.subtitle + order.amount}
			/>
    </div>
  {/each}
</div>
