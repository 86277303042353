<script>
  export let icon = "avatar";
  export let placeholder = "Username";
  export let value = "";
  export let isSearch;
</script>

<style>
  .textInput {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 0 15px 0 55px;
    background: #ffffff;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }

  img {
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .searchInput {
    flex-direction: row-reverse;
    max-width: 530px;
  }

  .searchInput input {
    border-radius: unset;
    border: none;
    border-bottom: 1px solid #fff;
    font-size: 24px;
    background: transparent;
    padding: 0 55px 0 15px;
    color: #fff;
    font-weight: 200;
  }

  .searchInput img {
    left: unset;
    right: 20px;
    width: 32px;
    height: 38px;
  }

  .searchInput input::-webkit-input-placeholder {
    color: #fff;
  }

  .searchInput input::-moz-placeholder {
    color: #fff;
  }

  .searchInput input::-moz-placeholder {
    color: #fff;
  }

  .searchInput input:-moz-placeholder {
    color: #fff;
  }
</style>

<div class="textInput" class:searchInput={isSearch}>
  {#if icon}
    <img src={`icons/${icon}.svg`} alt="" />
  {/if}

  <input type="text" on:change on:keypress bind:value {placeholder} />
</div>
