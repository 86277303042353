<script>
	import {push} from 'svelte-spa-router';
	import {fly, slide, scale, blur, fade} from "svelte/transition";
	import checkout from "../data/checkout.json";
	import {defaultProduct} from "../data/defaultProduct";
	import BackButton from "../components/_ui/BackButton.svelte";
	import CheckoutSteps from "../components/_ui/CheckoutSteps.svelte";
	import Delivery from "../components/checkout/Delivery.svelte";
	import {afterUpdate} from "svelte";
	import Payment from "../components/checkout/Payment.svelte";
	import Summary from "../components/checkout/Summary.svelte";

	export let params = {}

	let activeTask = 'Delivery';
	let activeStep = 'delivery'
	let checkoutProcess = checkout[0].delivery;
	let linkTextToUse = defaultProduct.linkText;

	let approveOrder = false;

	afterUpdate(() => {
		if (params.step) {
			activeStep = params.step
		}
	})


</script>

<style>
	h2 {
		color: #fff;
	}

	.checkout {
		position: relative;
		grid-column: auto / span 2;
		display: grid;
		grid-template-columns: 400px 1fr;
		grid-template-rows: max-content max-content auto;
	}

	.header {
		grid-column: auto / span 3;
		display: grid;
		grid-template-columns: 400px 1fr;
		gap: 30px;
		padding: 50px 0 20px;
		position: relative;
	}

	.checkoutStepsContainer {
		padding: 10px 0;
	}

	.headline {
		grid-column: auto / span 3;
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 30px;
		grid-row-gap: 10px;
	}

	.content {
		position: relative;
		grid-column: auto / span 2;
		display: grid;
		grid-template-columns: 400px 1fr;
		gap: 30px;
	}
	.overlayContainer {
		position: absolute;
		left: 105px;
		top: 0;
		right: 0;
		bottom: 0;
		backdrop-filter: blur(10px);
		background: rgba(45, 101, 175, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
	}

	.overlay {
		padding: 30px;
		background: #FFFFFF;
		border-radius: 5px;
		display: grid;
		gap: 30px;
		position: relative;
	}

	.title {
		font-weight: normal;
		font-size: 21px;
		margin: 0 0 20px;
	}

	.subtitle {
		font-weight: normal;
		font-size: 18px;
		margin: 0 0 14px;
	}
	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 20px;
		height: 20px;
		object-fit: contain;
	}
</style>

<div class="checkout">
	<div class="header">
		<div class="backButtonContainer">
			<BackButton on:click={() => push('/carts')} text="Return to carts" color="white"/>
		</div>

		<div class="checkoutStepsContainer">
			<CheckoutSteps active={activeStep}/>
		</div>
	</div>
	<div class="headline">
		<h2>Checkout Order “Project 736”</h2>
	</div>
	<div class="content">
		{#if !params.step}
			<Delivery activeStep={activeStep} checkoutProcess={checkoutProcess} activeTask={activeTask}/>
			{:else if params.step === 'payment'}
			<Payment/>
			{:else if params.step === 'summary'}
			<Summary handleClickApproveOrder={() => approveOrder = true}/>
		{/if}
	</div>
</div>
{#if approveOrder}
	<div transition:fade class="overlayContainer" on:click={() => push('/dashboard')}>
		<div class="overlay">
			<img class="close" src="icons/close.svg" alt=""/>
			<div class="title">Thank you for your Order!</div>
			<div class="subtitle" style="margin: 0;">For inquiries please use your reference number</div>
			<div style="font-size: 18px">Order reference  Nr.: <b>7823329</b></div>
		</div>
	</div>
{/if}
