<script>
	import TextLink from "./TextLink.svelte";

	export let mainCategory
	export let subCategory

</script>

<style>
	.categoryTile {
		background: #FFFFFF;
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.3s ease-out;
		padding: 10px 10px 10px 15px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	.categoryTile:hover {
		background-color: #f7f7f7;
		transform: translate(2px, -2px);
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	}
	.titleSection {

	}
	.mainCategory {
		margin: 0 0 8px;
		font-weight: normal;
		font-size: 14px;
		color: #888888;
	}
	.subCategory {
		font-weight: normal;
		font-size: 18px;
		color: #333333;
	}
	.textLinkContainer {
		color: #233691;
		cursor: pointer;
		border-bottom: 1px solid rgba(0, 0, 0, 0);
		transition: border-bottom-color 0.2s ease-out;
		text-decoration: none;
	}
	.textLinkContainer:hover {
		border-bottom: 1px solid #233691;
	}
	.textLinkInner {
		display: grid;
		grid-template-columns: auto 16px;
		gap: 15px;
	}
	.textLinkIcon {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
</style>

<div class="categoryTile">
	<div class="titleSection">
			<div class="mainCategory">{mainCategory}</div>
			<div class="subCategory">{subCategory}</div>
	</div>
	<div class="textLinkContainer">
		<div class="textLinkInner">
			<div>View products</div>
			<img class="textLinkIcon" src={'icons/arrow-next.svg'} alt=""/>
		</div>
	</div>

</div>
