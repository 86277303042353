export const carts = [
	{
		title: 'Warehouse lighting project',
		user: 'Created by Cathy Parks',
		message: 'Lorem ipsum dolor',
	},
	{
		title: 'Harbor construction site - cables and connectors',
		user: 'Created by Evan Mccullough',
		message: 'Lorem ipsum dolor',
	},
	{
		title: 'Heating renovation project',
		user: 'Created by Youssef Deleon',
		message: 'Order for $ 300 on light bulbs to meet target',
	}
]
