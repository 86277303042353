<script>
	import {slide} from 'svelte/transition'
	import {createEventDispatcher} from 'svelte';

	const dispatch = createEventDispatcher();

	export let id = 'DropdownButton' + Math.round(Math.random() * 100000);
	export let title = 'DropdownButton'
	export let open = false;

	function handleToggleDropdown(e) {
		e.preventDefault()
		e.stopPropagation()
		//open = !open;
		dispatch('onToggle', {open: open, id: id, title: title})
	}

	function handleContentClick(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	$: arrowToUse = open ? 'icons/dropdown-arrow-down.svg' : 'icons/arrow-down.svg';
</script>

<style>
	.dropdown {
		user-select: none;
		position: relative;
		width: fit-content;
	}

	.header {
		padding: 8px 18px 7px 23px;
		display: flex;
		align-items: center;
		background: rgba(255, 255, 255, 0);
		transition: background 200ms ease-out;
		width: fit-content;
		border-radius: 4px;
	}

	.headerActive {
		background: #fff;
	}

	.title {
		color: #fff;
		margin: 0 10px 0 0;
	}

	.titleActive {
		color: #233691;
	}

	.icon {
		width: 16px;
		height: 16px;
		object-fit: contain;
		transform: rotate(0deg);
		transition: transform 200ms ease-out;
	}

	.iconRotated {
		transform: rotate(180deg);
	}

	.content {
		position: absolute;
		z-index: 1000;
		width: 250px;
		padding: 20px 15px;
		border-radius: 4px;
		background: #fff;
		margin: 6px 0 0 0;
		right: 0;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	}
</style>

<div class="dropdown">
	<div on:click={handleToggleDropdown} class="header" class:headerActive={open}>
		<div class="title" class:titleActive={open}>{@html title}</div>
		<img class="icon" class:iconRotated={open} src={arrowToUse} alt=""/>
	</div>
	{#if open}
		<div class="content" transition:slide on:click={handleContentClick}>
			<slot/>
		</div>
	{/if}
</div>
