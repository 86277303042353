<script>
  import { createEventDispatcher } from "svelte";
  export let direction = "down";
  const dispatch = createEventDispatcher();

  function handleOnClick() {
    dispatch("click");
  }
</script>

<style>
  button {
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    border: 0;
    background: none;
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    transform: scale(1);
    transition: all 0.2s ease-out;
  }
  button:hover {
    transform: scale(1.05);
  }
  .icon {
    transition: all 0.2s ease-out;
    transform: rotate(0) translateY(2px);
    margin-left: 7px;
    display: inline-block;
		transform: scale(.85);
  }

  .direction--up .icon {
    transform: translateY(2px) rotate(-180deg);
  }
</style>

<button class="direction--{direction}" on:click={handleOnClick}>
  <slot />
  <img class="icon" src="icons/arrow-down.svg" alt="" />
</button>
