<script>
	import {slide} from "svelte/transition";

	export let image;
	export let orderDate = '18.02.2021';
	export let title;
	export let subtitle = "13-04-2021";
	export let status = "";
	export let amount;
	export let explanation = "";
	export let error = false;
	export let success = false;

	export let nextActions = [
		{
			name: "track & trace",
			action: null
		}
	];

	export let active = false;
</script>

<style>
	.Quote__container {
		margin: 0 -10px;
	}
	.Quote {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 6px 20px;
		padding: 10px;
		font-size: 16px;
		border-bottom: 1px solid #e0e0e0;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
	}

	.Quote:hover {
		background-color: #f7f7f7;
		transform: translate(2px, -2px);
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	}

	.QuoteWithImage {
		grid-template-columns: 80px 1fr;
	}

	.title {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}

	.subtitle {
		font-size: 16px;
		color: #888;
	}

	.status {
		color: #333;
		justify-self: right;
	}

	.explanation {
		font-size: 14px;
		justify-self: right;
	}

	.amount {
		font-size: 16px;
		padding-top: 8px;
		color: #333;
	}

	.link {
		color: #333;
		justify-self: right;
		align-self: end;
		cursor: pointer;
		font-size: 14px;
	}

	.link:hover {
		text-decoration: underline;
	}

	.success {
		color: #037618;
	}

	.error {
		color: #cc0000;
	}

	.actions {
		padding: 15px 10px 10px 10px;
		width: 100%;
		grid-column: span 2;
		background-color: #F2F3F4;
		position: relative;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		z-index: 1;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.date{
		text-align: right;
		color: #888;
	}
	.infoSection {
		display: flex;
		justify-content: space-between;
		margin: 4px 0 0 0;
		align-items: flex-end;
	}
	.action {
		cursor: pointer;
		padding: 10px;
		border-bottom: 1px solid #e0e0e0;
	}

	.action:last-child {
		border-bottom: 0px solid #e0e0e0;
	}
</style>

<div class="Quote__container">
<div class="Quote" class:QuoteWithImage={!!image} on:click>
	{#if !!image}
		<img class="image" src={`img/products/${image}.jpg`} alt="" />
		<div>
		<div class="title">{title}</div>
			<div class="infoSection">
		<div class="subtitle">{subtitle}</div>
		<div class="date">{orderDate}</div>
			</div>
		</div>
	{:else}
		<div class="title">{title}</div>
		<div class="status" class:success class:error>{status}</div>
		<div class="subtitle">{subtitle}</div>
		<div class="explanation" class:success class:error>{explanation}</div>

		<div class="amount">
			{#if amount}$ {amount.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}{/if}
		</div>

		<div class="link">
			{status === 'partially shipped' || status === 'shipped' ? 'track & trace >' : ''}
		</div>

	{/if}
</div>
{#if active}
	<div class="actions" transition:slide>
		{#each nextActions as action (action.name)}
			<div class="action">{action.name}</div>
		{/each}
	</div>
{/if}
</div>
