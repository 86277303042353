<script>
  import Headline from "./Headline.svelte";

  export let title = undefined;
  export let icon = "icons/view-all-arrow-next.svg";
  export let disableViewAll = undefined;
  export let maxHeight = false;
  export let doubleRow = false;
  export let singleRow = false;
  export let textLinkText = 'view all'
  import { brand } from "../../Store.js";

  const handleOnClick = () => {
    console.log("clicked [view all]", title);
  };
</script>

<style>
  .Card {
    padding: 25px 30px;
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    max-height: 470px;
    border-radius: 5px;
  }

	.doubleRow {
		max-height: 780px;
	}

	.singleRow {
		max-height: 380px;
	}

  .maxHeight {
    max-height: 100%;
  }

  .Card__headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
	}

  .Card__viewAll {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    color: #233691;
    cursor: pointer;
    white-space: nowrap;
    transition: transform 0.2s ease-out;
  }

  .Card__viewAll.unie {
    color: #007d32;
	}

	.Card__viewAll.valtech {
		text-decoration: underline;
		color: #000000;
	}

	.Card__viewAll.valtech:hover {
		/* text-decoration: none; */
	}

  .Card__viewAll:hover {
    transform: scale(1.05);
  }

  .Card__viewAllIcon {
    font-size: 16px;
    margin: 0 0 0 8px;
  }

  .Card__viewAll.unie .Card__viewAllIcon {
    filter: hue-rotate(245deg);
  }

  .Card::-webkit-scrollbar {
    width: 6px;
  }

  .Card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  .Card::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }

  @media (max-width: 640px) {
    .Card {
      max-width: none;
    }
  }
</style>

<div class="Card" class:maxHeight class:doubleRow class:singleRow>
  {#if title || !disableViewAll}
    <div class="Card__headerContainer">
      {#if title}
        <Headline type="h2">{title}</Headline>
      {/if}

      {#if !disableViewAll}
        <div
          class="Card__viewAll"
          class:unie={$brand === 'unie'}
					class:valtech={$brand === 'valtech'}
          on:click={handleOnClick}>
					{textLinkText}
          <img class="Card__viewAllIcon" src={icon} alt="" />
        </div>
      {/if}
    </div>
  {/if}

  <slot />
</div>
