<script>
	export let value = 10;
	export let extended = false
	export let size = 'normal'

	function countUp() {
		value++;
	}

	function countDown() {
		value = Math.max(0, --value);
	}
</script>

<style>
	.amountCounterButton {
		display: flex;
		align-items: center;
		padding: 10px;
		border: 1px solid #888888;
		height: 45px;
		border-radius: 5px;
		color: #888888;
		font-size: 30px;
		background: #fff;
		width: fit-content;
		cursor: pointer;
		user-select: none;
		min-width: 85px;
	}

	.extended {
		padding: 0;
		height: 50px;
	}

	.amountCounterButton.fullWidth {
		width: 100%;
	}

	.extended .value {
		padding: 0;
	}

	.value {
		font-size: 21px;
		color: #333333;
		padding: 0 10px;
	}
	.extended .value {
		font-size: 14px;
	}

	.alignMinus {
		height: 100%;
		display: flex;
		align-items: center;
		margin: 0 0 4px;
		padding: 0 10px 0 20px;
	}

	.plus {
		height: 100%;
		padding: 0 20px 0 10px;
		font-size: 24px;
		margin: 0;
		display: flex;
		align-items: center;
	}

	.title {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
		font-size: 14px;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.counter {
		height: 100%;
		flex-grow: 2;
		display: flex;
		align-items: center;
		border-left: 1px solid #888888;
		justify-content: space-between;
	}
</style>

<div class={`amountCounterButton ${size}`} class:extended={extended}>
	{#if extended}
		<div class="title">
			QTY
		</div>
		<div class="counter">
			<div class="alignMinus" on:click={countDown}>-</div>
			<div class="value">{value}</div>
			<div on:click={countUp} class="plus">+</div>
		</div>
	{:else}
		<div class="alignMinus" on:click={countDown}>-</div>
		<div class="value">{value}</div>
		<div on:click={countUp}>+</div>
	{/if}
</div>
