export const moreProducts = [
	{
		title: 'Maxus Deco Led - bulb ND 4.3-40w E27 - Philips',
		image: 'light-bulb',
		artNo: 'MSGU50W93036D',
		ean: '4058075094957',
		totalPrice: 280,
		pcs: 10,
		gross: 181.7,
		recupel: 6.7,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lights',
		subCategories: ['light bulb', 'LED', 'led'],
		manufacturer: 'Philips',
	},
	{
		title: 'RF receiver 12-36VDC PWM Exit 4X5A Master/Slave - Integratech',
		image: 'LEDdriver',
		artNo: '540 SR-1009PD',
		ean: '5420076216658',
		totalPrice: 18,
		pcs: 10,
		gross: 17,
		recupel: 1,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lighting',
		subCategories: ['Accessories', 'LED drivers'],
		manufacturer: 'Integratech',
	},
	{
		title: 'Wallyx GU10 wall mounted lamp 50W or 5W PowerLED 230V dark grey - SLV',
		image: 'wallmountedlamp',
		artNo: '582 227195',
		ean: '4024163113304',
		totalPrice: 52,
		pcs: 2,
		gross: 43,
		recupel: 1.5,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lighting',
		subCategories: ['Lamps', 'Indoor'],
		manufacturer: 'SLV',
	},
	{
		title: 'Streetlight Mini 57W 4000K 7410LM Grey Type2 IP66 IK10 65X155° - Technolux',
		image: 'streetlightmini',
		artNo: '401 LDM57S4-2',
		ean: '6052165383304',
		totalPrice: 211,
		pcs: 1,
		gross: 176,
		recupel: 8.21,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lighting',
		subCategories: ['Lamps', 'Outdoor'],
		manufacturer: 'Technolux',
	},
	{
		title: 'Switch 10A 250V AC, N.O., White - Niko',
		image: 'switch',
		artNo: '36 101-60010',
		ean: '5413736216365',
		totalPrice: 15.1,
		pcs: 4,
		gross: 14.3,
		recupel: 0.8,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Installation material',
		subCategories: ['Sockets and switches', 'Switches'],
		manufacturer: 'Niko',
	},
	{
		title: 'Wire tray GR-Magic 50X55 (3,9) - Galvanized - OBO Bettermann',
		image: 'wiretray',
		artNo: 'GRM 55 50 G',
		ean: '4012195430957',
		totalPrice: 14.61,
		pcs: 1,
		gross: 11.64,
		recupel: 0.2,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Installation material',
		subCategories: ['Cable conduit, trunking and routing', 'Cable trays'],
		manufacturer: 'OBO Bettermann',
	},
	{
		title: 'White Helmet With Face Protector Screen, EN 397 / EN 166 - Catu',
		image: 'helmet',
		artNo: 'MO185BL',
		ean: '4012195498371',
		totalPrice: 43.86,
		pcs: 1,
		gross: 38.43,
		recupel: 2.1,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lighting',
		subCategories: ['Light bulbs', 'LED'],
		manufacturer: 'Catu',
	},
	{
		title: 'Peltor X4 33dB Ear Defender and Head Band - 3M',
		image: 'eardefender',
		artNo: '20 PEX4A',
		ean: '4046719695515',
		totalPrice: 35.12,
		pcs: 1,
		gross: 29.95,
		recupel: 2.1,
		currency: 'USD',
		locale: 'en-US',
		textLink: 'all-warehouses',
		linkText: 'See all',
		availability: 'In stock',
		availabilityColor: '#00A651',
		thumbnails: ['detail-second'],
		mainCategory: 'Lighting',
		subCategories: ['Light bulbs', 'LED'],
		manufacturer: '3M',
	}
]
