<script>
  import ArrowLink from "../_ui/ArrowLink.svelte";
  import Card from "../_ui/Card.svelte";
  import Orders from "../_cards/Orders.svelte";
  import RevenueCounter from "../_ui/RevenueCounter.svelte";
  import PieChart from "../_ui/PieChart.svelte";
  import BarChart from "../_ui/BarChart.svelte";
  import { activePersona, activePersonaKey, revenue } from "../../Store";
  import { fly, slide, fade } from "svelte/transition";
  import ProductTeaser from "../_cards/ProductTeaser.svelte";
</script>

<style>
  .board {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px 0;
    grid-auto-flow: row dense;
    grid-auto-rows: min-content;
    align-items: start;
  }

  .board h2 {
    grid-column: 1 / 3;
  }

  h2 {
    color: #fff;
  }

  .orders {
    grid-row: auto / span 2;
  }

  .quotes {
    grid-row: auto / span 2;
  }

  .avgquotes {
    grid-row: auto / span 1;
  }

  .news {
    grid-row: auto / span 1;
  }

  .ordervalue {
    grid-row: auto / span 1;
  }

  .topclients {
    height: 100%;
    grid-column: 2;
    grid-row: auto / span 2;
	}

  .board__headline {
    display: flex;
    justify-content: space-between;
  }

  .news h2 {
    margin: 0 0 15px;
  }
  .news img {
    height: 158px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 5px;
  }
  .newsTeaser {
    display: grid;
    gap: 20px;
  }

  @media (max-width: 640px) {
    .board {
      grid-template-columns: 1fr;
    }

    .board h2,
    .orders,
    .quotes,
    .avgquotes,
    .ordervalue,
    .topclients {
      grid-column: auto;
      grid-row: auto;
    }
  }
</style>

<div
  class="board"
  in:fade={{ x: -200, duration: 500, delay: 500 }}
  out:fade={{ x: 200, duration: 500 }}>
  {#if $activePersonaKey === 'seller'}
    <h2 class="board__headline">
      Commerce
      <ArrowLink>Filter customer</ArrowLink>
    </h2>

    <div class="quotes">
      <Card title="Active Quotes">
        <Orders orders={$activePersona.quotes} />
      </Card>
    </div>

    <div class="orders">
      <Card title="Active Orders">
        <Orders orders={$activePersona.orders} />
      </Card>
    </div>

    <h2>Reports</h2>

    <div class="ordervalue">
      <Card title="Revenue (YTD)">
        <RevenueCounter />
      </Card>
    </div>

    <div class="topclients">
      <Card title="Top customers (12M rolling)">
        <PieChart />
      </Card>
    </div>

    <div class="avgquotes">
      <Card title="Average order value">
        <BarChart />
      </Card>
    </div>
  {:else if $activePersonaKey === 'customer'}
    <h2 class="board__headline">Commerce</h2>

    <div class="avgquotes">
      <Card title="Carts and Quotes" singleRow>
        <Orders orders={$activePersona.quotes} />
      </Card>
    </div>

    <div class="avgquotes">
      <Card title="Recent orders" singleRow>
        <ProductTeaser />
      </Card>
    </div>

    <div class="avgquotes">
      <Card title="Bestsellers" singleRow>
        <ProductTeaser />
      </Card>
    </div>

    <div class="news">
      <h2>News</h2>
      <div class="newsTeaser">
        <img src="img/news1.png" alt="" />
        <img src="img/news2-eng.png" alt="" />
      </div>
    </div>
  {/if}
</div>
