<script>
	import TreeNode from "./TreeNode.svelte";
	export let onClick = () => true;
	export let selectedTreeNode = ''
	export let catalogTree = []
</script>

<style>
	.catalogTree {
		overflow: hidden;
		padding: 20px 10px 0 20px;
	}
</style>

<div class="catalogTree">
	{#each catalogTree as treeNode, index (treeNode.title + index)}
		<TreeNode {...treeNode} onClick={onClick} selectedTreeNode={selectedTreeNode}/>
	{/each}
</div>
