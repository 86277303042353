<script>
  export let variant = "primary";
  export let size = "normal";
  import { brand } from "../../Store.js";
</script>

<style>
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    border-radius: 5px;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    padding: 12px 30px;
    box-sizing: border-box;
    border: none;
    appearance: none;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
		white-space: nowrap;
  }

  .button.sonepar {
    background: #233691;
    border: 2px solid #233691;
  }

  .button.unie {
    background: #007d32;
    border: 2px solid #007d32;
	}

	.button.valtech {
    background: #000000;
    border: 2px solid #000000;
  }

  .button.secondary.sonepar {
    background: #7dcaed;
    border: 2px solid #7dcaed;
  }

  .button.secondary.unie {
    background: #80ad19;
    border: 2px solid #80ad19;
	}

	.button.secondary.valtech {
    background: #ffffff;
		border: 2px solid #000000;
		color: #000000;
	}
	.button.secondary.valtech:hover {
    background: #000000;
		border: 2px solid #000000;
		color: #ffffff;
  }

	.button.transparent {
		background: transparent;
		border: none;
		color: #233691;
	}

  .button.icon {
    padding: 0 30px;
    display: flex;
    align-items: center;
  }

  .button.extend {
    width: 100%;
  }
	.button.thick {
		height: 50px;
	}
	.button.slim {
		height: 30px;
		padding: 0;
	}
</style>

<button class={`button ${$brand} ${variant} ${size}`} on:click>
  <slot />
</button>
