<script>
  import { afterUpdate, beforeUpdate } from "svelte";
  import { expoOut } from "svelte/easing";
  import Chart from "chart.js";
  import { brand } from "../../Store.js";

  export let data = [
    {
      value: 712345,
      color: "#d82f61",
      name: "Green Energy LLC"
    },
    {
      value: 654231,
      color: "#aacc34",
      name: "Electrical Systems Solutions LLC"
    },
    {
      value: 543111,
      color: "#3ddfdc",
      name: "Industrial Co."
    },
    {
      value: 456987,
      color: "#debd3c",
      name: "Good Solutions Co."
    },
    {
      value: 345789,
      color: "#89389b",
      name: "A & A Engineering"
    },
    {
      value: 264523,
      color: "#0f8dbb",
      name: "Others"
    }
  ];

  const pieId = Math.round(Math.random() * 1000000);
  let visible, pieChart, ctx, hoverPieIndex, prevProps, hasUpdated;

  function drawChart(animationDuration) {
    ctx = document.getElementById(`pieChart-${pieId}`).getContext("2d");
    if (pieChart) {
      pieChart.destroy();
    }
    pieChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: data.map(d => d.name),
        datasets: [
          {
            label: "# of Votes",
            data: data.map(d => d.value),
            backgroundColor: data.map(d => d.color),
            borderColor: data.map(d => d.color),
            borderWidth: 1
          }
        ]
      },
      options: {
        layout: {
          padding: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          }
        },
        legend: {
          display: false
        },
        animation: {
          duration: animationDuration
        },
        tooltips: {
          enabled: false,
          custom: function(tooltipModel) {
            if (tooltipModel.dataPoints) {
              handleOnHoverPie(tooltipModel.dataPoints[0].index);
            } else {
              handleOnHoverPie(undefined);
            }
          }
        }
      }
    });
  }

  function handleOnHoverPie(index) {
    hoverPieIndex = index;
  }

  function fillUp() {
    return {
      delay: Math.round(Math.random() * 500),
      duration: 500,
      easing: expoOut,
      css: t => `height: ${t * 100}%`
    };
  }

  function showDots() {
    setTimeout(() => {
      visible = true;
    }, 200);
  }

  function isEqual(prev, curr) {
    let isEqual;
    if (!prev) {
      isEqual = false;
    } else {
      const previous = prev.map(pp => [pp.name, pp.value].join("")).join(""),
        current = curr.map(d => [d.name, d.value].join("")).join("");

      isEqual = previous === current;
    }
    return isEqual;
  }

  beforeUpdate(() => {
    hasUpdated = !isEqual(prevProps, data);
    if (hasUpdated) {
      visible = false;
    }
  });

  afterUpdate(() => {
    if (hasUpdated) {
      drawChart(1000);
      showDots();
      prevProps = data;
    }
  });
</script>

<style>
  .pieChart__container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 0 0;
    filter: hue-rotate(0);
    will-change: filter;
  }

  .pieChart__container.sonepar {
    filter: hue-rotate(245deg);
	}
	.pieChart__container.unie {
    filter: hue-rotate(165deg);
	}
	.pieChart__container.valtech {
    filter: hue-rotate(0deg);
  }

  .description {
    display: grid;
    grid-template-columns: 10px auto 75px;
    grid-auto-rows: 45px;
    grid-column-gap: 15px;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .descriptionColor {
    height: 100%;
  }

  .descriptionLabel {
    font-size: 10px;
  }

  .descriptionValue {
    font-size: 12px;
  }

  .pieChart {
    width: 100%;
    max-width: 300px;
  }
  .hoverOnPie {
    background-color: #f7f7f7;
    transform: translate(2px, -2px);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }
</style>

<svelte:window on:resize={() => drawChart(1)} />
<div class="pieChart__container" class:unie={$brand === 'unie'}  class:sonepar={$brand === 'sonepar'} class:valtech={$brand === 'valtech'}>
  <div>
    {#each data as item, index}
      <div
        class={hoverPieIndex === index ? 'description hoverOnPie' : 'description'}>
        {#if visible}
          <div
            class="descriptionColor"
            in:fillUp
            style={`background-color: ${item.color}`} />
        {:else}
          <div />
        {/if}
        <div class="descriptionLabel">{item.name}</div>
        <div class="descriptionValue">$ {item.value.toLocaleString()}</div>
      </div>
    {/each}
  </div>
  <div class="pieChart" id="chartContainer">
    <canvas id={`pieChart-${pieId}`} width="1" height="1" />
  </div>
</div>
