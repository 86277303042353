<script>
  import { slide } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import TextInput from "../components/_ui/TextInput.svelte";
  import ProductTile from "../components/_cards/ProductTile.svelte";
  import { products, cableCategories, manufacturers } from "../data/products";
  import SelectedFilterGroup from "../components/_ui/SelectedFilterGroup.svelte";
  import SearchProduct from "../components/_ui/SearchProduct.svelte";
  import { onMount, afterUpdate, beforeUpdate } from "svelte";
  import CategoryTile from "../components/_ui/CategoryTile.svelte";
  import LessMoreLink from "../components/_ui/LessMoreLink.svelte";
  import {
    searchQuery,
    filteredProducts,
    filteredSubcategories
  } from "../Store";

  export let params = {};

  onMount(() => {
    if (!!params.searchQuery) {
      searchQuery.update(() => params.searchQuery);
    }
  });

  const maxTiles = 4;

  let showMoreProducts = false;

  function handleOnClickProduct(id) {
    push(`/catalog/${id}`);
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if ($searchQuery !== "") {
        push(`/search/${$searchQuery}`);
      } else {
        push("/search");
      }
    }
  };
</script>

<style>
  .search {
    grid-column: auto / span 2;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 150px 1fr;
    gap: 30px;
    color: #fff;
    padding: 0 0 25px;
  }

  .header {
    grid-column: auto / span 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 30px;
    height: 130px;
  }

  h2 {
    margin: 0 0 20px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .categories {
    display: grid;
    gap: 15px;
    margin: 0 0 53px;
  }

  .manufacturers {
    display: grid;
    gap: 15px;
  }

  .lessMoreContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
</style>

<div
  class="search"
  in:slide={{ x: -200, duration: 500, delay: 500 }}
  out:slide={{ x: 200, duration: 500 }}>
  <div class="header">
    <TextInput
      on:keypress={handleKeyPress}
      bind:value={$searchQuery}
      isSearch={true}
      icon={'search-input'}
      placeholder={'Search ...'} />
  </div>

  <div>
    {#if !$searchQuery}
      <h2>Recent Search</h2>

      <SelectedFilterGroup />
    {:else}
      <div>
        <h2>Categories ({$filteredSubcategories.length})</h2>

        <div class="categories">
          {#each $filteredSubcategories as category, index (category)}
            <CategoryTile
              mainCategory={'Cables and wires'}
              subCategory={category} />
          {/each}
        </div>
      </div>

      <h2>Manufacturers ({manufacturers.length})</h2>

      <div class="manufacturers">
        {#each manufacturers as category, index (category)}
          <CategoryTile
            mainCategory={'Cables and wires'}
            subCategory={category} />
        {/each}

        <div class="lessMoreContainer">
          <LessMoreLink />
        </div>
      </div>
    {/if}
  </div>

  <div>
    {#if !$searchQuery}
      <h2>Most viewed products</h2>

      <div class="content">
        {#each products.slice(0, maxTiles) as product, index (product.ean)}
          <ProductTile
            isListStyle={true}
            {product}
            on:click={() => handleOnClickProduct(product.ean)} />
        {/each}
      </div>
    {:else}
      <div>
        <h2>Products ({$filteredProducts.length})</h2>

        <div class="content">
          {#each $filteredProducts.slice(0, showMoreProducts ? 99 : maxTiles) as product, index (product.ean)}
            <ProductTile
              isListStyle={true}
              {product}
              on:click={() => handleOnClickProduct(product.ean)} />
          {/each}

          {#if $filteredProducts.length > maxTiles}
            <div class="lessMoreContainer">
              <LessMoreLink
                handleClick={() => (showMoreProducts = !showMoreProducts)}
                showMore={showMoreProducts}
                text={`Show all products for "${$searchQuery}"`} />
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>
