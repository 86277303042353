<script>
	import {push} from 'svelte-spa-router';
	import {fade} from 'svelte/transition';
	import checkout from '../../data/checkout.json';
	import OrderOverview from "../OrderOverview.svelte";
	import CreditLimitCard from "../_cards/CreditLimitCard.svelte";
	import AddressCard from "../_ui/AddressCard.svelte";
	import Task from "../tasks/Task.svelte";
	import {products as dummyProducts} from '../../data/products';
	import Button from "../_ui/Button.svelte";

	export let products = dummyProducts.slice(0, 8);

	export let tasks = [
		{
			"message": "Summary",
			"client": "Pay with company credit",
		}
	]

	export let delivery = checkout[0].delivery;

	export let payment = {
		"title": "Payment method",
		"paymentDetails": {
			"title": "Payment",
			"address": {
				"name": "Kicklasch GmbH",
				"street": "Scharnhorststraße 1",
				"city": "50733 Köln"
			},
			"creditLimit": {
				"title": "Company Credit Limit",
				"available": 48983.50,
				"max": 50000
			}
		}
	};
	export let activeTask = 'Summary';
	export let handleClickApproveOrder = () => true

</script>

<style>
	.allorders {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		gap: 10px;
		padding: 20px 0;
	}

	.orderdetail {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-auto-rows: min-content;
		gap: 10px 20px;
		padding: 20px 0;
		grid-auto-flow: dense row;
	}

	.orderdetail h2 {
		grid-column: span 2;
	}

	.shopcard {
		grid-column: 1;
	}

	.orderoverview {
		grid-column: 2;
		grid-row: span 4;
	}

	.orderoverview__container {
		display: grid;
		grid-row-gap: 10px;
	}

	.orderDetails {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		background: #fff;
		padding: 25px;
	}

	.summary {
		padding: 5px;
		color: #000000;
		display: grid;
		gap: 30px;
	}

	.deliveryDetails {
		display: grid;
		grid-template-columns: auto auto auto;
		grid-column-gap: 15px;
		grid-row-gap: 30px;
	}

	.title {
		font-weight: normal;
		font-size: 21px;
		margin: 0 0 20px;
	}

	.subtitle {
		font-weight: normal;
		font-size: 18px;
		margin: 0 0 14px;
	}

	.text {
		font-weight: normal;
		font-size: 16px;
	}

	.boldText {
		font-weight: 600;
		margin: 0 0 5px;
	}

	.productsSummary {
		display: grid;
		gap: 20px;
	}

	.product {
		display: grid;
		grid-template-columns: 40px 1fr auto auto;
		gap: 20px;
		align-items: center;
		color: #333333;
		font-weight: 600;
	}

	.productImage {
		width: 40px;
		height: 40px;
		object-fit: contain;
	}

	.productTitle {
		font-size: 12px;
		padding: 0 10px;
	}

	.productPcs {
		font-size: 14px;
		text-align: right;
	}

	.productPrice {
		font-size: 16px;
		text-align: right;
		color: #142C6A;
	}

	@media only screen and (max-width: 500px) {
		.payment {
			min-height: unset;
		}
	}
</style>
<div
				class="allorders"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	{#each tasks as task (task.message)}
		<Task
						message={task.message}
						client={task.client}
						preventDate={true}
						preventInfo={true}
						order
						active={task.message === activeTask}
						handleClick={() => (activeTask = task.message)}/>
	{/each}
</div>
<div
				class="orderdetail"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	<div>
		<div class="orderDetails">
			<div class="summary">
				<div>
					<div class="title">{delivery.title}</div>
					<div class="deliveryDetails">
						<div>
							<div class="text boldText">{payment.paymentDetails.address.name}</div>
							<div class="text">{payment.paymentDetails.address.street}</div>
							<div class="text">{payment.paymentDetails.address.city}</div>
						</div>
						<div>
							<div class="text boldText">Delivery contact:</div>
							<div class="text">{delivery.deliveryContact.contact.firstName} {delivery.deliveryContact.contact.lastName}</div>
							<div class="text">{delivery.deliveryContact.contact.phone}</div>
						</div>
						<div>
							<div class="text boldText">Estimated delivery:</div>
							<div class="text">04. May 2021</div>
						</div>
						<div>
							<div class="subtitle">Invoice address</div>
							<div class="text boldText">{payment.paymentDetails.address.name}</div>
							<div class="text">{payment.paymentDetails.address.street}</div>
							<div class="text">{payment.paymentDetails.address.city}</div>
						</div>
						<div>
							<div class="subtitle">Payment</div>
							<div class="text boldText">Company Credit</div>
							<div class="text">{payment.paymentDetails.creditLimit.available}</div>
						</div>
					</div>
				</div>
				<div>
					<div class="title">Order Overview</div>
					<div class="productsSummary">
						{#each products as product, index (product.title)}
							<div class="product">
								<img class="productImage" src={`img/products/${product.ean}.jpg`} alt=""/>
								<div class="productTitle">{product.title}</div>
								<div class="productPcs">{product.pcs} pcs</div>
								<div class="productPrice">$ {product.totalPrice}</div>
							</div>
						{/each}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="orderoverview">
		<div class="orderoverview__container">
			<OrderOverview handleOnClickApprove={handleClickApproveOrder} approveButtonText="Submit order">
				<Button variant="transparent" size="slim" on:click={() =>push('/order')}>Return to cart</Button>
			</OrderOverview>
			<AddressCard/>
		</div>
	</div>
</div>
