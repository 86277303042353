<script>
  import LoginTile from "../components/login/LoginTile.svelte";
  import { fade, slide } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import { brand } from "../Store.js";

  let loading = false;

  const login = () => {
    loading = true;
    setTimeout(() => {
      push("/dashboard");
    }, 2000);
  };
</script>

<style>
  .login {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
	}

  .sonepar {
    background: linear-gradient(180deg, #0492d7 -21.81%, #26398d 124.54%);
  }

  .unie {
    background: linear-gradient(180deg, #bfc50d -18.76%, #007d32 42.98%);
  }

	.valtech {
    background: #00bdfa;
	}

	.login.valtech img.logo {
		width: 240px;
	}

  .content {
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 60px;
  }

  .loginForm {
    width: 450px;
  }

  .loading {
    width: 400px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ring {
    width: 280px;
    height: 100%;
    object-fit: contain;
    transform-origin: center center;
    animation-name: spin360;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  .secondring {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 3;
  }

  @keyframes spin360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
	}


	.lds-ring {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-ring div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
	}
	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}
	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}
	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

</style>

<div
  class="login"
  class:valtech={$brand === 'valtech'}
	class:sonepar={$brand === 'sonepar'}
  class:unie={$brand === 'unie'}
  transition:fade={{ duration: 500, delay: 1000 }}>
  <div class="content">
    {#if !loading}
			{#if $brand === 'valtech'}
				<img class="logo" transition:fade={{ duration: 500 }} src="img/logo-valtech-white.svg" alt="" />
			{:else if $brand === 'unie'}
				<img class="logo" transition:fade={{ duration: 500 }} src="img/logo-unie.png" alt="" />
			{:else}
				<img class="logo" transition:fade={{ duration: 500 }} src="img/sonepar_logo_large.svg" alt="" />
			{/if}
    {/if}

    {#if loading}
      <div class="loading" in:fade={{ duration: 500, delay: 1000 }}>
				{#if $brand === 'valtech'}
					<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
				{:else if $brand === 'unie'}
          <img class="ring" src="img/logo-unie.png" alt="" />
        {:else}
          <img class="ring secondring" src="img/logo-ring-1.png" alt="" />
          <img class="ring" src="img/logo-ring-2.svg" alt="" />
        {/if}
      </div>
    {/if}

    {#if !loading}
      <div class="loginForm" transition:fade={{ duration: 500 }}>
        <LoginTile handleLoginClick={login} />
      </div>
    {/if}
  </div>
</div>
