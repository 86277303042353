<script>
  import Chart from "chart.js";
  import { afterUpdate } from "svelte";
  import { brand } from "../../Store.js";

  const barId = Math.round(Math.random() * 1000000);

  export let data = [
    {
      value: 18025,
      name: "oct"
    },
    {
      value: 15360,
      name: "nov"
    },
    {
      value: 17324,
      name: "dec"
    },
    {
      value: 20833,
      name: "jan"
    }
  ];

  let barChart, ctx;

  function drawChart(animationDuration) {
    ctx = document.getElementById(`barChart-${barId}`).getContext("2d");
    if (barChart) {
      barChart.destroy();
    }
    barChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.map(d => d.name),
        datasets: [
          {
            label: "",
            data: data.map(d => d.value),
            backgroundColor: "#d82f61",
            borderColor: "#d82f61",
            borderWidth: 1
          }
        ]
      },
      options: {
        layout: {
          padding: {
            top: 10
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "#888888"
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              id: "",
              gridLines: {
                drawBorder: false,
                borderDash: [6, 6],
                borderDashOffset: 0.5,
                lineWidth: 2,
                zeroLineWidth: 2,
                zeroLineColor: "#dddddd"
              },
              ticks: {
                display: false,
                beginAtZero: true,
                max: getMaxScale(data),
                min: 0,
                stepSize: 250000
              }
            }
          ]
        },
        tooltips: {
          enabled: false
        },
        hover: {
          animationDuration: 1
        },
        animation: {
          duration: animationDuration,
          onComplete: function() {
            let chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.textAlign = "center";
            ctx.fillStyle = "#333333";
            ctx.textBaseline = "bottom";
            if (window.innerWidth < 1300) {
              ctx.font = "10px sans-serif";
            } else {
              ctx.font = "12px sans-serif";
            }

            this.data.datasets.forEach(function(dataset, i) {
              let meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                let data = "$ " +dataset.data[index].toLocaleString();
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        }
      }
    });
  }

  function getMaxScale(data) {
    const max = Math.max.apply(null, data.map(d => d.value));
    return Math.ceil((max + 100000) / 250000) * 25000;
  }

  afterUpdate(() => drawChart(1000));
</script>

<style>
  .barChart {
    position: relative;
    padding: 5px 0 0 0;
  }
	.barChart.sonepar {
		filter: hue-rotate(245deg);
  }
  .barChart.unie {
    filter: hue-rotate(165deg);
	}
	.barChart.valtech {
		filter: hue-rotate(0deg);
  }
</style>

<svelte:window on:resize={() => drawChart(1)} />

<div class="barChart" class:unie={$brand === 'unie'} class:sonepar={$brand === 'sonepar'} class:valtech={$brand === 'valtech'}>
  <canvas id={`barChart-${barId}`} width="3" height="1" />
</div>
