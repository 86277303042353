<script>
	import { slide, fly } from 'svelte/transition';
	import { brand } from "../../Store.js";
	export let value = 0.25;
	export let max = 1;

	$: valueToUse = Math.min((100 / max) * value, 100)
</script>

<style>
	.progressBar {
		position: relative;
		width: 100%;
		height: 20px;
		background: #EEF2F7;
	}
	.progress {
		position: absolute;
		background: #233691;
		left: 0;
		top: 0;
		bottom: 0;
		transition: right 200ms ease-out;
	}
	.progress.valtech {
		background: #00c6d8;
	}
</style>

<div class="progressBar">
	<div class="progress" class:valtech={$brand === 'valtech'} style={`right: calc(100% - ${valueToUse}%)`}></div>
</div>

