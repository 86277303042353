<script>
	import {flip} from 'svelte/animate'
	import {quintOut} from 'svelte/easing'
	import {slide} from 'svelte/transition'
	import Card from "../_ui/Card.svelte";
	import {defaultProduct} from "../../data/defaultProduct";
	import Price from "../_ui/Price.svelte";
	import AmountCounterButton from "../_ui/AmountCounterButton.svelte";
	import Button from "../_ui/Button.svelte";
	import { brand } from "../../Store.js";

	export let isListStyle = true
	export let product = defaultProduct

</script>

<style>
	.productTile {
		display: grid;
		grid-template-columns: 1fr;
		padding: 15px;
		background: #fff;
		border-radius: 4px;
		color: #333333;
		justify-items: center;
		height: 100%;
		transition: all 0.3s ease-in-out;
	}

	.productTile:hover {
		background-color: #f7f7f7;
		transform: translate(2px, -2px);
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	}

	.alignContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.alignToBottom {
		justify-content: flex-end;
	}

	.listStyle {
		max-width: unset;
		grid-template-columns: 1fr 210px;
		grid-column-gap: 25px;
		justify-items: unset;
		height: unset;
	}

	.listStyle .clickableSection {
		display: grid;
		grid-template-columns: 97px 1fr;
		grid-column-gap: 25px;
		cursor: pointer;
	}

	.listStyle .image {
		width: 100%;
		height: 100%;
		object-fit: contain;
		margin: 0;
	}

	.listStyle .details {
		width: unset;
	}

	.listStyle .buttonSection {
		grid-template-columns: 1fr;
		gap: 13px;
	}

	.listStyle .title {
		text-align: left;
	}

	.listStyle .totalPrice {
		align-self: flex-end;
	}

	.image {
		width: 154px;
		height: 154px;
		margin: 0 0 8px;
		object-fit: contain;
	}

	.inner {
		display: flex;
		justify-content: space-between;
	}

	.textSection {

	}

	.title {
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 8px;
		text-align: center;
	}

	.details {
		/* display: flex;
		justify-content: space-between; */
		margin: 0 0 8px;
		width: 100%;

	}

	.details div {
		margin: 0 0 3px;
	}

	.availability {
		color: #888888;
	}

	.availabilityValue {
		font-weight: bold;
	}

	.pcs {
		color: #233691;
		text-align: right;
	}

	.pcs.valtech {
		color: #000000;
	}

	.priceSection {
		max-width: 180px;
		text-align: right;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.buttonSection {
		display: grid;
		gap: 15px;
		grid-template-columns: auto auto;
	}

	.totalPrice {
		font-size: 32px;
		font-weight: 600;
		color: #142C6A;
	}

	.totalPrice.valtech {
		color: #000000;
	}

	.tabSection {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 20px;
		grid-row-gap: 5px;
		margin: 0 0 10px 0;
	}

	.tabText {
		text-align: left;
	}

</style>

<div in:slide={{ x: -200, duration: 500, delay: 500 }}
		 out:slide={{ x: 200, duration: 500 }}>
	{#if isListStyle}
		<div class="productTile listStyle">
			<div class="clickableSection" on:click>
				<img class="image" src={`img/products/${product.ean}.jpg`} alt=""/>
				<div class="inner">
					<div class="textSection">
						<div class="title">
							{product.title}
						</div>
						<div class="details">
							<div>Article: <span>{product.artNo}</span></div>
							<div>EAN: <span>{product.ean}</span></div>
						</div>
						<div class="availability">Availability:
							<span class="availabilityValue"
										style="color: {product.availabilityColor || '#000'}">{product.availability}</span>
						</div>
					</div>
					<div class="priceSection">
						<div class="tabSection">
							<div class="tabText">Gross:</div>
							<Price value={product.gross}/>
							<div class="tabText">Recupel:</div>
							<Price value={product.recupel}/>
						</div>
						<div class="pcs" class:valtech={$brand === 'valtech'}>{product.pcs} pcs</div>
						<div class="totalPrice" class:valtech={$brand === 'valtech'}>
							$ {product.totalPrice}
						</div>
					</div>
				</div>
			</div>
			<div class="buttonSection">
				<AmountCounterButton extended={true} size="fullWidth"/>
				<Button variant={'secondary'} size="thick">Add to cart</Button>
			</div>
		</div>
	{:else}
		<div class="productTile">
			<div class="alignContent" on:click>
				<img class="image" src={`img/products/${product.ean}.jpg`} alt=""/>
				<div class="title">
					{product.title}
				</div>
			</div>
			<div class="alignContent alignToBottom">
				<div class="totalPrice">
					$ {product.totalPrice}
				</div>
				<div class="details">
					<div class="availability">
						<div>Availability:</div>
						<div class="availabilityValue"
								 style="color: {product.availabilityColor || '#000'}">{product.availability}</div>
					</div>
					<div>
						<div class="availability">Packaging options:</div>
						<div class="pcs">{product.pcs} pcs</div>
					</div>
				</div>
				<div class="buttonSection">
					<AmountCounterButton extended={true}/>
					<Button variant={'secondary'} size="thick">Add to cart</Button>
				</div>
			</div>
		</div>
	{/if}
</div>
