export const sortBy = [
	{
		label: `Product name (A <img style="width: 10px; height: 10px; object-fit: contain" src="icons/arrow-next.svg" alt=""/> Z)`,
	},
	{
		label: `Product name (Z <img style="width: 10px; height: 10px; object-fit: contain" src="icons/arrow-next.svg" alt=""/> A)`,
	},
	{
		label: `Price (low <img style="width: 10px; height: 10px; object-fit: contain" src="icons/arrow-next.svg" alt=""/> high)`,
	},
	{
		label: `Price (high <img style="width: 10px; height: 10px; object-fit: contain" src="icons/arrow-next.svg" alt=""/> low)`,
	},
	{
		label: `Popularity (low <img style="width: 10px; height: 10px; object-fit: contain" src="icons/arrow-next.svg" alt=""/> high)`,
	},
]
