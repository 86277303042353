<script>
	import {fade} from 'svelte/transition';
	import _shuffle from 'lodash/shuffle'
	import ArrowLink from "../components/_ui/ArrowLink.svelte";
	import CatalogTree from "../components/_ui/CatalogTree.svelte";
	import ProductTile from "../components/_cards/ProductTile.svelte";
	import {products} from '../data/products'
	import {push, pop, location} from "svelte-spa-router";
	import ProductDetail from "./ProductDetail.svelte";
	import DropdownButton from "../components/_ui/DropdownButton.svelte";
	import Dropdown from "../components/_ui/Dropdown.svelte";
	import Checkbox from "../components/_ui/Checkbox.svelte";
	import Button from "../components/_ui/Button.svelte";
	import CartSelection from "../components/_ui/CartSelection.svelte";
	import {carts} from "../data/carts";
	import filterDropdowns from "../data/filters.json";
	import catalogTree from '../data/catalogtreeV1_4.json';
	import {sortBy} from '../data/sortby';
	import RadioButtonGroup from "../components/_ui/RadioButtonGroup.svelte";
	import RadioButton from "../components/_ui/RadioButton.svelte";

	export let params = {}

	const highestOrder = catalogTree.map(treeItem => treeItem.title)

	let productsToUse = products;
	let listView = true
	let breadcrumb = []
	let selectedProject = 'Warehouse lighting project';
	let filterButtonOpen = [false, false, false]
	$: cartTitleToUse = `<b>: ${selectedProject}</b>`
	let selectedSortBy = sortBy[0].label.replace(/ <.+> /, '');

	function handleToggleView() {
		listView = !listView;
	}

	function handleOnClickTreeNode(breadcrumbFromNode, isVisible) {
		productsToUse = _shuffle(productsToUse)
		breadcrumb = breadcrumbFromNode
		if (breadcrumbFromNode.length === 1 && !isVisible) {
			breadcrumb = []
		}
	}

	function handleOnClickProduct(ean) {
		let newLocation = $location.replace(/\/\d+/g, '')
		push(`${newLocation}/${ean}`)
		breadcrumb = [...breadcrumb, products.filter(p => p.ean === ean)[0].title]
	}

	function handleClickProductSlider(e) {
		console.log('product click: ', e.detail)
		handleOnClickProduct(e.detail.id)
	}

	function handleBackClick() {
		breadcrumb = breadcrumb.filter((b, i) => i < breadcrumb.length - 1)
		pop()
	}

	function handleToggleCart(id) {
		selectedProject = id;
		setTimeout(() => {
			closeFilters()
		}, 400)
	}

	function handleOnToggleFilterButton(e, index) {
		e.preventDefault()
		e.stopPropagation()
		filterButtonOpen = [false, false, false];
		filterButtonOpen[index] = !e.detail.open;
	}

	function closeFilters() {
		filterButtonOpen = [false, false, false];
	}

	function handleOnChange(id) {
		console.log(id)
		selectedSortBy = id;
	}
</script>

<style>
	.catalog {
		grid-column: auto / span 2;
		display: grid;
		grid-template-columns: 250px 1fr;
		gap: 20px;
		margin: 0 0 0 -30px;
	}

	.catalogOverview {
		display: grid;
		grid-template-rows: 96px 1fr;
		padding: 25px 0;
	}

	.catalogDetails {
		display: grid;
		grid-template-rows: 96px 1fr;
		padding: 25px 0;
	}

	.header {
		display: flex;
		align-items: center;
		padding: 0 0 0 30px;
	}

	.filterSection {
		justify-content: space-between;
	}

	.breadcrumb {
		max-width: 410px;
		display: flex;
		align-items: center;
		color: #fff;
		flex-wrap: wrap;
	}

	.breadcrumbDivider {
		width: 13px;
		height: 16px;
		object-fit: contain;
		margin: 0 5px -3px;
	}

	.filters {
		display: grid;
		grid-template-columns: 50px auto auto auto;
		/*grid-column-gap: 25px;*/
	}

	h2 {
		color: #fff;
	}

	.content {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 15px;
		position: relative;
	}

	.contentListView {
		grid-template-columns: 1fr;
	}

	.brandFilterContainer {
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
		/*max-height: 185px;*/
		padding: 10px;
		overflow-y: scroll;
		width: 100%;
		display: grid;
		gap: 12px;
	}

	.sortByFilterContainer {
		box-shadow: none;
	}

	/*.brandFilterContainer::-webkit-scrollbar {*/
	/*	width: 6px;*/
	/*}*/

	/*.brandFilterContainer::-webkit-scrollbar-track {*/
	/*	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);*/
	/*}*/

	/*.brandFilterContainer::-webkit-scrollbar-thumb {*/
	/*	background-color: #aaa;*/
	/*}*/

	.filterButtonSection {
		margin: 30px 0 0 0;
		display: grid;
		gap: 10px;
	}

	.darken {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(45, 101, 175, 0.3);
	}

	.treeSection {
		background: rgba(255, 255, 255, 0.8);
	}

	@media only screen and (max-width: 641px) {
		.content {
			grid-template-columns: 1fr;
		}
	}
</style>

<svelte:window on:click={closeFilters}/>
<div class="catalog">
	<div class="catalogOverview"
			 in:fade={{ x: -200, duration: 500, delay: 500, }}
			 out:fade={{ x: 200, duration: 500 }}
	>
		<div class="header">
			<h2>
				Catalog
			</h2>
		</div>
		<div class="treeSection">
			<div>
				<CatalogTree
								catalogTree={catalogTree}
								onClick={handleOnClickTreeNode}
								selectedTreeNode={breadcrumb[breadcrumb.length - 1]}
				/>
			</div>
		</div>
	</div>
	<div class="catalogDetails"
			 in:fade={{ x: -200, duration: 500, delay: 500 }}
			 out:fade={{ x: 200, duration: 500 }}
	>
		<div class="header filterSection">
			<div class="breadcrumb">
				{#each breadcrumb as item, index}
					{#if index !== 0}
						<div><img class="breadcrumbDivider" src="icons/breadcrumb-divider.svg" alt=""/></div>
					{/if}
					{#if index === breadcrumb.length -1}
						<div><b>{item}</b></div>
					{:else}
						<div>{item}</div>
					{/if}
				{/each}
			</div>
			<div class="filters">
				<div on:click={handleToggleView}>
					{#if !listView}
						<img class="viewIcon" src="icons/view-list.svg" alt=""/>
					{:else}
						<img class="viewIcon" src="icons/view-tiles.svg" alt=""/>
					{/if}
				</div>
				<DropdownButton title="Filter" open={filterButtonOpen[0]} on:onToggle={(e) => handleOnToggleFilterButton(e, 0)}>
					{#each filterDropdowns as dropdown}
						<Dropdown title={dropdown.title}>
							<div class="brandFilterContainer">
								{#each dropdown.filters as filter}
									<Checkbox {...filter}/>
								{/each}
							</div>
						</Dropdown>
					{/each}
				</DropdownButton>
				<DropdownButton title="Sort by ..." open={filterButtonOpen[1]}
												on:onToggle={(e) => handleOnToggleFilterButton(e, 1)}>
					<div class="brandFilterContainer sortByFilterContainer">
						{#each sortBy as item, index (item.label)}
							<RadioButton {...item} onchange={() => handleOnChange(item.label.replace(/ <.+> /, ''))} checked={selectedSortBy === item.label.replace(/ <.+> /, '')}/>
						{/each}
					</div>
				</DropdownButton>
				<DropdownButton title={`Cart${cartTitleToUse}`} open={filterButtonOpen[2]}
												on:onToggle={(e) => handleOnToggleFilterButton(e, 2)}>
					{#each carts as cart, index (cart.title)}
					<CartSelection on:click={() => handleToggleCart(cart.title)}
												 title={cart.title} subtitle={cart.user} selected={selectedProject === cart.title}/>
					{/each}
					<div class="filterButtonSection">
						<Button variant={'secondary'}>Add to new cart</Button>
					</div>
				</DropdownButton>
			</div>
		</div>
		{#if params.product}
			<ProductDetail on:onProductClick={handleClickProductSlider} backTo={breadcrumb[breadcrumb.length - 2]}
										 product={products.filter(p => p.ean === params.product)[0]} on:onBackClick={handleBackClick}/>
		{:else}
			<div class="content" class:contentListView={listView}>
				{#each productsToUse as product (product.ean)}
					<ProductTile isListStyle={listView} product={product} on:click={() => handleOnClickProduct(product.ean)}/>
				{/each}
				{#if filterButtonOpen.includes(true)}
					<div transition:fade class="darken"></div>
				{/if}
			</div>
		{/if}
	</div>
</div>
