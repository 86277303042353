<script>
	import {fade} from 'svelte/transition';

	export let title = 'Warehouse lighting project';
	export let subtitle = 'Company user';
	export let selected = false;
</script>

<style>
	.cartFilterContainer {
		padding: 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #D8D8D8;
		cursor: pointer;
	}
	.cartFilterContainer:hover {
		background-color: #f7f7f7;
		transform: translate(2px, -2px);
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	}

	.selected .title {
		color: #233691;
	}

	.title {
		font-weight: 600;
		font-size: 16px;
		color: #333333;
		margin: 0 0 6px 0;
	}

	.subtitle {
		font-size: 12px;
		font-weight: normal;
		color: #888888;
	}
</style>

<div class="cartFilterContainer" class:selected={selected} on:click>
	<div>
		<div class="title">{title}</div>
		<div class="subtitle">{subtitle}</div>
	</div>
	{#if selected}
		<img transition:fade src={'icons/checkmark.svg'} alt=""/>
	{/if}
</div>
