<script>
	export let checked = undefined;
	export let label = 'Remember me';
	import { brand } from "../../Store.js";
</script>

<style>
	.checkbox {
		position: relative;
		cursor: pointer;
		user-select: none;
		padding: 0 0 0 34px;
		margin: 0 0 3px;
		-webkit-user-select: none;
		background: #FFFFFF;
		font-size: 16px;
		line-height: 22px;
	}
	.checkbox input {
		width: 0;
		height: 0;
		cursor: pointer;
		position: absolute;
		opacity: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 24px;
		width: 24px;
		background: #fff;
		border: 1px solid #26398D;
		border-radius: 2px;
		cursor: pointer;
	}

	.checkbox:hover input ~ .checkmark {

	}

	.checkbox input:checked ~ .checkmark {

	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	.checkbox input:checked ~ .checkmark:after {
		display: block;
	}

	.checkbox .checkmark:after {
		left: 8px;
		top: 3px;
		width: 4px;
		height: 10px;
		border: solid #26398D;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	label {
		font-size: 16px;
		line-height: 19px;
		color: #26398D;
		cursor: pointer;
	}

	label.valtech {
		color: #000000;
	}

	label.valtech .checkmark:after {
		border-color: #000000;
	}

</style>

<div class="checkbox">
	<label class:valtech={$brand === 'valtech'}>
		{@html label}
		<input type="checkbox" bind:checked on:change>
		<span class="checkmark"></span>
	</label>
</div>
