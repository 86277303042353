<script>
	import RadioButtonGroup from "../components/_ui/RadioButtonGroup.svelte";
	import Dropdown from "../components/_ui/Dropdown.svelte";
	import Textarea from "../components/_ui/Textarea.svelte";
	import ProgressBar from "../components/_ui/ProgressBar.svelte";
	import DropdownButton from "../components/_ui/DropdownButton.svelte";
	import Checkbox from "../components/_ui/Checkbox.svelte";
	import SelectedFilterButton from "../components/_ui/SelectedFilterButton.svelte";

</script>

<style>
	.test {
		grid-column: auto / span 2;
	}

	.addAddress {
		color: #233691;
		font-size: 20px;
		font-weight: normal;
		margin: 15px 0 0 36px;
	}

	.calendar {
		width: 100%;
		max-width: 288px;
		height: 224px;
		object-fit: contain;
	}
	.dropdownButtonExample {
		margin: 0 0 0 200px;
	}
</style>

<div class="test">
	<Dropdown>
		<RadioButtonGroup/>
		<div class="addAddress">+ Add new address</div>
	</Dropdown>
	<Dropdown title={'Schedule delivery'}>
		<img class="calendar" src={'img/calendar.svg'} alt=""/>
	</Dropdown>
	<Dropdown>
		<Textarea/>
	</Dropdown>

	<ProgressBar value={0.25}/>

	<div class="dropdownButtonExample">
		<DropdownButton>
			<Dropdown>
				<Checkbox/>
				<Checkbox/>
			</Dropdown>
			<Dropdown>
				<Checkbox/>
				<Checkbox/>
			</Dropdown>
			<Dropdown>
				<Checkbox/>
				<Checkbox/>
			</Dropdown>
		</DropdownButton>
	</div>
	<SelectedFilterButton>Phillips Lighting</SelectedFilterButton>
</div>

