<script>
	export let steps = [
		{
			id: 'delivery',
			text: 'Delivery details',
		},
		{
			id: 'payment',
			text: 'Payment method',
		},
		{
			id: 'summary',
			text: 'Summary'
		}
	];
	export let active = 'Delivery details';
</script>

<style>
	.checkoutSteps {
		display: flex;
		align-items: center;
		color: #FFFFFF;
		font-size: 16px;
	}

	.step {
		font-weight: normal;
		margin: 0 20px 0 0;
	}

	.active {
		font-weight: 600;
	}
</style>

<div class="checkoutSteps">
	{#each steps as step, index (step.id)}
		<div class="step" class:active={active === step.id}>{index + 1}. {step.text}</div>
	{/each}
</div>
