<script>
  export let value = 0;
  export let currency = "USD";
  export let locale = "en-US";

  const valueToUse = new Intl.NumberFormat(locale, {
		style: "currency",
    currency: currency
  }).format(value);
</script>

<style>
  .price {
    display: inline;
    /* font-weight: 200; */
    font-size: 18px;
  }
</style>

<div class="price">{valueToUse}</div>
