<script>
	export let text = 'Back'
	export let color = 'blue'

	let iconToUse = color === 'white'
					? 'icons/arrow-back-white.svg'
					: 'icons/arrow-back.svg'
</script>

<style>
	.backButton {
		display: flex;
		align-items: center;
		padding: 10px;
		cursor: pointer;
		color: #233691;
	}
	.backButton.white {
		color: #FFFFFF;
	}
	.icon {
		margin: 0 10px 0 0;
		width: 13px;
		height: 13px;
		object-fit: contain;
	}
	.text {
		font-weight: normal;
		font-size: 14px;
	}
</style>

<div on:click class={`backButton ${color}`}>
	<img class="icon" src={iconToUse} alt=""/>
	<div class="text">{text}</div>
</div>
