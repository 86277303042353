<script>
  import { slide } from "svelte/transition";
  import { push } from "svelte-spa-router";

  import DateDisplay from "../_ui/DateDisplay.svelte";
  import IconButtonWithText from "../_ui/IconButtonWithText.svelte";

  export let message = "Upcoming appointment";
  export let client = "Feenstra";
  export let info =
    "Upcoming " +
    new Date().toLocaleString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
  export let date = new Date("2021-03-01").toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
  export let nextActions = [
    {
      icon: "call",
      text: "Call customer"
    },
    {
      icon: "route",
      text: "Route"
    },
    {
      icon: "notes",
      text: "Notes"
    },
    {
      icon: "details",
      text: "Details"
    }
  ];

  let active = false;

  const handleClick = () => {
    active = !active;
  };
</script>

<style>
  .upcoming {
    width: 100%;
    font-size: 14px;
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  .main {
    background-color: #fff;
    display: grid;
    grid-template-columns: 25px 1fr;
    padding: 12px 15px;
    gap: 0 20px;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0);
    border-radius: 5px;
    overflow: hidden;
  }

  .main:hover {
    background-color: #f7f7f7;
    transform: translate(0px, -2px);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .main.active {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
  }

  .message {
    grid-column: 1 / 3;
  }

  .client {
    grid-column: 1 / 3;
  }

  .info {
    color: #888888;
  }

  .date {
    color: #888888;
    justify-self: end;
  }

  .actions {
    padding: 17px 10px 10px 10px;
    width: 100%;
    grid-column: span 2;
    background-color: #f2f2f2;
    position: relative;
    top: -7px;
    border-radius: 0 0 5px 5px;
    z-index: 1;
    margin-bottom: -7px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
</style>

<div class="upcoming" class:active on:click={handleClick}>
  <div class="main" class:active>
    <div class="icon">
      <img src={`icons/upcoming-appointment.svg`} alt={message} />
    </div>

    <div class="content">
      <h3 class="message">{message}</h3>
			<div class="client">{client}</div>
      <div class="info">{info.toLocaleString(undefined, {style: 'currency', minimumFractionDigits: 2,maximumFractionDigits: 2})}</div>
      <div class="date">{date}</div>
    </div>
  </div>

  {#if active}
    <div class="actions" class:active transition:slide>
      {#each nextActions as action (action.icon)}
        <IconButtonWithText icon={action.icon} text={action.text} />
      {/each}
    </div>
  {/if}
</div>
