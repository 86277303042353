<script>
	import { products as dummyProducts } from '../../data/products'
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();
	export let products = dummyProducts;
	export let title = 'Alternative products';

	function handleClick(id) {
		console.log('handleCLick: ', id)
		dispatch('onProductClick', {id: id})
	}
</script>

<style>
	.productSlider {
		position: relative;
		overflow: hidden;
		user-select: none;
	}
	.title {
		font-weight: normal;
		font-size: 18px;
		line-height: 38px;
		color: #333333;
		margin: 0 0 12px;
		text-align: center;
	}
	.slideContainer {
		overflow-x: scroll;
		margin: 0 50px;
		position: relative;
		display: flex;
	}
	.slideContainer::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	.slide {
		margin: 0 45px 0 0;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.slide:last-child {
		margin: 0;
	}
	.slideImg {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
	.slideTitle {
		width: 150px;
		font-weight: normal;
		font-size: 14px;
		line-height: 19px;
		color: #333333;
	}
	.arrow {
		width: 21px;
		height: 21px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}
	.arrowRight {
		right: 0;
		left: auto;
	}
</style>

<div class="productSlider">
	<div class="title">{title}</div>
	<img class="arrow" src='icons/arrow-back.svg'/>
	<img class="arrow arrowRight" src='icons/arrow-next.svg'/>
	<div class="slideContainer">
		{#each products as product, index}
			<div class="slide" on:click={() => handleClick(product.ean)}>
				<img draggable="false" class="slideImg" src={`img/products/${product.ean}.jpg`}/>
				<div class="slideTitle">
					{product.title}
				</div>
			</div>
		{/each}
	</div>
</div>
