<script>
	import { createEventDispatcher } from "svelte";
	import { brand } from "../../Store.js";
  export let text = '';
  export let linkTo = false;

  const dispatch = createEventDispatcher();

  function handleOnClick() {
    dispatch("click", { linkTo: linkTo });
  }
</script>

<style>
  a {
    color: #233691;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: border-bottom-color 0.2s ease-out;
    text-decoration: none;
  }
  a:hover {
    border-bottom: 1px solid #233691;
	}
	a.valtech {
		color: #000000;
	}
	a.valtech:hover {
		border-bottom-color: #000000;
	}
</style>

<a class={`${$brand}`} href={linkTo ? `#${linkTo}` : '#/'} on:click={handleOnClick}>
	{text}
	<slot/>
</a>
