<script>
  import Router from "svelte-spa-router";
  import Sidebar from "./components/Sidebar.svelte";
  import Dashboard from "./routes/Dashboard.svelte";
  import Orderboard from "./routes/Orderboard.svelte";
  import Catalog from "./routes/Catalog.svelte";
  import Login from "./routes/Login.svelte";
  import { location, querystring } from "svelte-spa-router";
  import { onMount, afterUpdate } from "svelte";
  import { activePersonaKey, brand } from "./Store.js";
  import KitchenSink from "./routes/KitchenSink.svelte";
  import Search from "./routes/Search.svelte";
  import Checkout from "./routes/Checkout.svelte";
  import Cartboard from './routes/Cartboard.svelte';

  const routes = {
    "/dashboard": Dashboard,
    "/carts": Cartboard,
    "/orders": Orderboard,
    "/catalog/:product?": Catalog,
    "/settings": KitchenSink,
    "/search/:searchQuery?": Search,
    "/checkout/:step?": Checkout,
    "/": Login
  };

  onMount(() => {
    if ($querystring === "buyer" || $querystring === "customer") {
      activePersonaKey.set("customer");
    } else if ($querystring === "seller") {
      activePersonaKey.set("seller");
    }
  });
</script>

<style>
  main {
    display: grid;
    grid-template-columns: 105px 400px 1fr;
    gap: 30px;
    min-height: 100vh;
    padding-right: 20px;
    transition: all 0.3s ease-in-out;
  }

  .sonepar {
    background: linear-gradient(180deg, #0492d7 -21.81%, #26398d 124.54%);
  }

  .unie {
    background: linear-gradient(180deg, #bfc50d -18.76%, #007d32 42.98%);
  }

	.valtech {
		background: #00bdfa;
	}

  @media (max-width: 640px) {
    main {
      grid-template-columns: minmax(0, 1fr);
      padding: 60px 10px 10px 10px;
    }
	}

</style>

<main class:sonepar={$brand === 'sonepar'} class:valtech={$brand === 'valtech'} class:unie={$brand === 'unie'}>
  <Sidebar />

  <Router {routes} />
</main>
