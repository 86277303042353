<script>
	import {push} from 'svelte-spa-router';
	import {fade} from 'svelte/transition';
	import OrderOverview from "../OrderOverview.svelte";
	import CreditLimitCard from "../_cards/CreditLimitCard.svelte";
	import AddressCard from "../_ui/AddressCard.svelte";
	import Task from "../tasks/Task.svelte";
	import Button from "../_ui/Button.svelte";


	export let tasks = [
		{
			"message": "Invoice",
			"client": "Pay with company credit",
		},
		{
			"message": "Other payment method",
			"client": "Choose another payment type",
		}
	]

	export let payment = {
		"title": "Payment method",
		"paymentDetails": {
			"title": "Payment",
			"address": {
				"name": "Kicklasch GmbH",
				"street": "Scharnhorststraße 1",
				"city": "50733 Köln"
			},
			"creditLimit": {
				"title": "Company Credit Limit",
				"available": 48983.50,
				"max": 50000
			}
		}
	};
	export let activeTask = 'Invoice';

</script>

<style>
	.allorders {
		display: grid;
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		gap: 10px;
		padding: 20px 0;
	}

	.orderdetail {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-auto-rows: min-content;
		gap: 10px 20px;
		padding: 20px 0;
		grid-auto-flow: dense row;
	}

	.orderdetail h2 {
		grid-column: span 2;
	}

	.shopcard {
		grid-column: 1;
	}

	.orderoverview {
		grid-column: 2;
		grid-row: span 4;
	}

	.orderoverview__container {
		display: grid;
		grid-row-gap: 10px;
	}

	.orderDetails {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		background: #fff;
		padding: 25px;
	}

	.addAddress {
		color: #233691;
		font-size: 20px;
		font-weight: normal;
		margin: 27px 0 0 0;
		cursor: pointer;
	}

	.payment {
		padding: 5px;
		min-height: 560px;
		color: #000000;
	}

	.paymentTextSection {
		margin: 0 0 45px;
	}

	.paymentTitle {
		font-weight: normal;
		font-size: 21px;
		margin: 0 0 20px;
	}

	.paymentSubtitle {
		font-weight: normal;
		font-size: 18px;
		margin: 0 0 14px;
	}

	.paymentText {
		font-weight: normal;
		font-size: 16px;
	}

	.boldText {
		font-weight: 600;
		margin: 0 0 5px;
	}

	@media only screen and (max-width: 500px) {
		.payment {
			min-height: unset;
		}
	}
</style>
<div
				class="allorders"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	{#each tasks as task (task.message)}
		<Task
						message={task.message}
						client={task.client}
						preventInfo={true}
						preventDate={true}
						order
						active={task.message === activeTask}
						handleClick={() => (activeTask = task.message)}/>
	{/each}
</div>
<div
				class="orderdetail"
				in:fade={{ x: -200, duration: 500, delay: 500 }}
				out:fade={{ x: 200, duration: 500 }}>
	<div>
		<div class="orderDetails">
			<div class="payment">
				<div class="paymentTextSection">
					<div class="paymentTitle">{payment.paymentDetails.title}</div>
					<div class="paymentSubtitle">Invoice address</div>
					<div class="paymentText"><b>{payment.paymentDetails.address.name}</b></div>
					<div class="paymentText">{payment.paymentDetails.address.street}</div>
					<div class="paymentText">{payment.paymentDetails.address.city}</div>
					<div class="addAddress">+ Add new address</div>
				</div>
				<div class="paymentTextSection">
					<div class="paymentTitle">{payment.paymentDetails.creditLimit.title}</div>
					<CreditLimitCard/>
				</div>
			</div>
		</div>
	</div>
	<div class="orderoverview">
		<div class="orderoverview__container">
			<OrderOverview handleOnClickApprove={() => push('/checkout/summary')} approveButtonText="Review order">
				<Button variant="transparent" size="slim" on:click={() =>push('/order')}>Return to cart</Button>
			</OrderOverview>
			<AddressCard/>
		</div>
	</div>
</div>
