<script>
  import { fly, slide, fade } from "svelte/transition";
  import { push } from "svelte-spa-router";
  import { activePersona, activePersonaKey, revenue } from "../Store";
  import DashboardTasks from "../components/tasks/DashboardTasks.svelte";
  import Board from "../components/tasks/Board.svelte";
  import TextInput from "../components/_ui/TextInput.svelte";

  function handleClick() {
    console.log("button clicked");
  }

  let activeTask;
  let showAllTasks = false;
  let searchValue = false;

  function handleOnClickTask(id) {
    if (id === activeTask) {
      activeTask = undefined;
      return;
    }
    activeTask = id;
  }

  function handleSearchInput(e) {
    console.log("event", e.target.value);
    searchValue = e.target.value;
  }

  function handleKeyPress(e) {
    searchValue = e.target.value;
    if (e.key === "Enter") {
      if (!!searchValue) {
        push(`/search/${searchValue}`);
      } else {
        push("/search");
      }
    }
  }
</script>

<style>
  .dashboard {
    grid-column: auto / span 2;
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 30px;
  }

  .buyer {
    grid-template-columns: 1fr 400px;
  }

  .header {
    grid-column: auto / span 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 30px;
    height: 130px;
  }

  @media (max-width: 767px) {
    .dashboard,
    .buyer {
      grid-column: unset;
      grid-template-columns: 1fr;
    }

    .header {
      grid-column: unset;
    }
	}
	</style>

<div
  class="dashboard"
  class:buyer={$activePersonaKey === 'customer'}
  in:slide={{ x: -200, duration: 500, delay: 500 }}
  out:slide={{ x: 200, duration: 500 }}>
  {#if $activePersonaKey === 'customer'}
    <div class="header">
      <TextInput
        on:keypress={handleKeyPress}
        handleChange={handleSearchInput}
        isSearch={true}
        icon={'search-input'}
        placeholder={'Search ...'} />
    </div>
    <Board />
    <DashboardTasks />
  {:else}
    <DashboardTasks />
    <Board />
  {/if}
</div>
