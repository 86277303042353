<script>
  export let type;
</script>

<style>
  .Headline {
    margin: 0;
    color: #000;
  }

  h1 {
  }

  h2 {
    font-size: 23px;
    line-height: 28px;
  }
</style>

{#if type === 'h1'}
  <h1 class="Headline">
    <slot />
  </h1>
{:else if type === 'h2'}
  <h2 class="Headline">
    <slot />
  </h2>
{:else}
  <h6 class="Headline">
    <slot />
  </h6>
{/if}
