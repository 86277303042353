<script>
  export let text = "lorem ipsum";
  export let icon = "plug";
  export let variant = "primary";

  let iconToUse = `icons/${icon}.svg`;
</script>

<style>
  button {
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: transparent;
    color: #333;
    padding: 20px;
    cursor: pointer;
  }

  .image {
    width: 48px;
    height: 32px;
    object-fit: contain;
  }

  .text {
    margin: 10px 0 0 0;
  }

  .quicklink {
    padding: 20px 10px 5px;
    height: unset;
    transition: all 0.3s ease-in-out;
  }

  .quicklink:hover {
    background-color: #f7f7f7;
    transform: translate(2px, -2px);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .quicklink .image {
    height: 24px;
    width: 100%;
  }

  .quicklink .text {
    font-size: 12px;
  }
</style>

<button class={variant} on:click>
  <img alt="" class="image" src={iconToUse} />

  <p class="text">{text}</p>
</button>
