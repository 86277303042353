<script>
	import IconButton from "../components/_ui/IconButton.svelte";
	import {activePersonaKey, brand} from "../Store.js";
	import {push, location} from "svelte-spa-router";
</script>

<style>
	.sidebar {
		width: 100%;
		height: 100%;
		max-height: 100vh;
		min-height: 100vh;
		padding: 30px 0;
		gap: 40px;
		background-color: #fff;
		display: grid;
		grid-template-rows: min-content 1fr min-content;
		align-items: center;
		justify-items: center;
		position: sticky;
		top: 0;
		overflow-y: auto;
	}

	.sidebar::-webkit-scrollbar {
		width: 6px;
	}

	.sidebar::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	.sidebar::-webkit-scrollbar-thumb {
		background-color: #aaa;
	}

	img {
		width: 64px;
		object-fit: contain;
	}

	.icons {
		display: grid;
		align-self: start;
		width: 100%;
	}

	.profile {
		justify-self: center;
		height: 65px;
		width: 24px;
		object-fit: contain;
		cursor: pointer;
	}

	.hamburger {
		display: none;
		margin-left: auto;
		margin-right: 5px;
	}

	.hamburger img {
		width: 20px;
	}

	@media (max-width: 640px) {
		.sidebar {
			height: 50px;
			background: #ffffff;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0 15px;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 100;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			min-height: unset;
		}

		.logo {
			width: 50px;
		}

		.icons {
			display: none;
		}

		.hamburger {
			display: block;
		}
	}
</style>

<div class="sidebar">
	<img
				class="logo"
				src={$brand === 'valtech' ? 'img/logo-valtech-v-white.svg' : 'img/logo-unie.png'}
				alt=""
				on:click={() => {
				if ($brand === 'valtech') {
				brand.set('sonepar');
				} else if (($brand === 'sonepar')) {
				brand.set('unie');
				} else {
				brand.set('valtech');
				}
				}}/>

	<div class="icons bladie">
		{#if $activePersonaKey === 'seller'}
			<div style="display:none">search is hidden for seller</div>
		{:else}
		<IconButton
						brand={$brand}
						name="search"
						handleClick={() => push('/search')}
						active={$location.includes('/search')}
		/>
		{/if}

		{#if $activePersonaKey === 'seller'}
			<IconButton
							brand={$brand}
							name="dashboard"
							handleClick={() => push('/dashboard')}
							active={$location === '/dashboard'} />
			<IconButton brand={$brand} name="tasks"/>
		{:else}
			<IconButton
							brand={$brand}
							name="carts"
							handleClick={() => push('/carts')}
							amount={3}
							active={$location === '/carts' || $location.includes('/checkout')} />
			<IconButton
							brand={$brand}
							name="dashboard"
							handleClick={() => push('/dashboard')}
							active={$location === '/dashboard'} />
			<IconButton
							brand={$brand}
							name="catalog"
							handleClick={() => push('/catalog')}
							active={$location.includes('/catalog')} />
		{/if}

		{#if $activePersonaKey === 'seller'}
			<IconButton name="customers"/>
			<!--    <IconButton name="commerce" />-->
			<IconButton name="reports"/>
			<IconButton name="intelligence"/>
			<IconButton name="portfolio"/>

			{:else}

			<IconButton name="orders-quotes"
									brand={$brand}
									amount={3}
									handleClick={() => push('/orders')}
									active={$location.includes('/orders')}
			/>
			<!--    <IconButton name="commerce" />-->
			<IconButton name="organization"/>
			<IconButton name="contracts-terms"/>
			<IconButton name="services"/>
			{/if}
	</div>

	<div class="icons">
		<img class="profile" src="img/profile.png" alt=""/>
		<!-- <IconButton name="settings" brand={$brand}
								handleClick={() => push('/settings')}
								active={$location === '/settings'}/> -->
		<IconButton name="logout" handleClick={() => push('/')}/>
	</div>

	<div class="hamburger">
		<img src="icons/navi.svg" alt="menu"/>
	</div>
</div>
