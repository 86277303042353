<script>
  import { createEventDispatcher } from "svelte";
  import Card from "../_ui/Card.svelte";
  import TextInput from "../_ui/TextInput.svelte";
  import Button from "../_ui/Button.svelte";
  import TextLink from "../_ui/TextLink.svelte";
  import Headline from "../_ui/Headline.svelte";
  import Checkbox from "../_ui/Checkbox.svelte";
	import { activePersonaKey } from "../../Store.js";
	import { brand } from "../../Store.js";

  export let handleLoginClick = () => true;

  const handleUsernameChange = e => {
		console.log("handleUsernameChange");
    if (["customer", "Customer", "buyer", "Buyer"].includes(e.target.value)) {
			console.log(e.target.value, "customer");
      activePersonaKey.set("customer");
    } else {
			console.log(e.target.value, "seller");
      activePersonaKey.set("seller");
    }
  };

  function handleKeyPress(e, field) {
		console.log("handleKeyPress", e, field);
		console.log("e.key", e.key);
  	if (e.key === 'Enter') {
  		if (field === 'username') {
				handleUsernameChange(e);
				console.log("handleKeyPress", e, field);
			}
			handleLoginClick()
		}
	}
</script>

<style>
  .loginTile {
    padding: 10px 20px;
  }

  .inputFields {
    padding: 15px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 25px;
	}
</style>

<Card disableViewAll={true}>
  <div class="loginTile">
    <Headline type="h2">Login</Headline>

    <div class="inputFields">
      <TextInput on:keypress={e => handleKeyPress(e, 'username')} handleChange={handleUsernameChange} />

      <TextInput on:keypress={handleKeyPress} placeholder="Password" icon="password" />
    </div>

    <div class="options" class:valtech={$brand === 'valtech'}>
      <Checkbox />

      <TextLink text="Forgot password?" linkTo="forgot-password" />
    </div>

    <Button variant="primary extend" on:click={handleLoginClick}>Login</Button>
  </div>
</Card>
