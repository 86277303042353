<script>
	import ArrowLink from "./ArrowLink.svelte";

	export let text;
	export let showMore = false;
	export let handleClick = () => true;
</script>

<ArrowLink on:click={handleClick} direction={showMore ? 'up' : 'down'}>
	{#if text}
		{showMore ? 'Show less' : text}
	{:else}
		Show {showMore ? 'less' : 'more'}
	{/if}
</ArrowLink>
