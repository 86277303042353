<script>
  import Card from "./Card.svelte";
  import { createEventDispatcher } from "svelte";
  import TextLink from "./TextLink.svelte";
  import IconButtonWithText from "./IconButtonWithText.svelte";

  const dispatch = createEventDispatcher();
  const defaultLinks = [
		{
			text: "Last viewed customers",
			linkTo: "last-viewed-customers",
			icon: "last-viewed-customers"
		},
		{
			text: "Weekly revenue report",
			linkTo: "weekly-revenue-report",
			icon: "weekly-revenue-report"
		},
		{
			text: "Newest products",
			linkTo: "newest-products",
			icon: "newest-products"
		},
		{
			text: "Latest orders",
			linkTo: "latest-orders",
			icon: "latest-orders"
		}
	];

	export let links = defaultLinks;
	export let title = 'Quick links';

  let linksToUse = defaultLinks.map((l, k) => {return {...l, ...links[k]}})

  function handleOnClick(linkTo) {
    console.log("quick link clicked: ", linkTo);
    dispatch("click", { linkTo: linkTo });
  }
</script>

<style>
  h2 {
    margin: 0 0 15px;
    color: #fff;
  }

  .quickLink__container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: dense column;
  }
</style>

<div class="quickLinks">
  <h2>{title}</h2>

  <Card disableViewAll={true}>
    <div class="quickLink__container">
      {#each linksToUse as link, index}
        <IconButtonWithText
          on:click={() => handleOnClick(link.linkTo)}
          icon={link.icon}
          text={link.text}
          variant="quicklink" />
      {/each}
    </div>
  </Card>

</div>
