<script>
	import {fly, slide, fade} from 'svelte/transition';
	import {flip} from 'svelte/animate';

	export let data = [
		{
			title: 'Product characteristics',
			content: {
				description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				bullets: [
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
				]
			}
		},
		{
			title: 'Product description',
			content: {
				description: 'CorePro LED bulbs are compatible with most current equipment equipped with E27 and B22 supports and are designed to replace halogen or incandescent bulbs. They enable impressive energy savings and reduce maintenance costs. CorePro LED bulbs are compatible with most current equipment equipped with E27 and B22 supports and are designed to replace halogen or incandescent bulbs.',
				bullets: [
					'Nominal voltage: 220 ... 240 V',
					'Lamp power: 10 ... 10 W',
					'Nominal current: 95 ... 95 mA',
					'Voltage type: AC',
				]
			}
		},
		{
			title: 'Technical Specifications',
			content: {
				description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				bullets: [
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
					'Lorem ipsum dolor sit amet',
				]
			}
		},
	]

	let selected = 'Product description'
	$: contentToUse = data
					.filter(d => d.title === selected)
					.map(d => d.content)[0];

	function handleNavClick(id) {
		selected = id;
	}
</script>

<style>
	.tabNavigation {

	}

	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.tabs {
		display: flex;
		justify-content: center;
	}

	.tab {
		margin: 0 50px 20px 0;
		padding: 6px 0;
		cursor: pointer;
		user-select: none;
	}

	.tab:last-child {
		margin: 0 0 20px;
	}

	.tab.selected {
		border-bottom: 2px solid #004A96;
	}

	.content {
		width: 100%;
		font-weight: 300;
		/* font-size: 14px; */
		color: #333333;
		gap: 10px;
		display: grid;
		line-height: 26px;
	}

	.bullet {
		display: flex;
		align-items: center;
	}

	.bulletIcon {
		width: 16px;
		height: 16px;
		object-fit: contain;
		margin: 0 10px 0 0;
	}

</style>

<div class="tabNavigation">
	<ul class="tabs">
		{#each data as tab, index (tab.title)}
			<li
				on:click={() => handleNavClick(tab.title)}
				class="tab"
				class:selected={selected === tab.title}>
				{tab.title}
			</li>
		{/each}
	</ul>
	<div class="content">
		<div>
			{contentToUse.description}
		</div>
		{#each contentToUse.bullets as bullet}
			<div class="bullet">
				<img class="bulletIcon" src={'icons/bullet-checkmark.svg'} alt=""/>
				<div>{bullet}</div>
			</div>
		{/each}
	</div>
</div>
